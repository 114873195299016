/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/



/* Global Component */

//전문가 네트워크
import networkTab  from '@/components/contents/networkTab'
Vue.component(networkTab.name, networkTab)

//컨설팅 프로세스
import consultingProcess from '@/components/contents/consultingProcess'
Vue.component(consultingProcess.name, consultingProcess)

//컨설팅 프로세스 - type-b
import consultingProcess_b from '@/components/contents/consultingProcess_b'
Vue.component(consultingProcess_b.name, consultingProcess_b)

//컨설팅 분야 바로가기
import consultingField from '@/components/contents/consultingField'
Vue.component(consultingField.name, consultingField)

//컨설팅 분야 바로가기 - type-b
import consultingField_b from '@/components/contents/consultingField_b'
Vue.component(consultingField_b.name, consultingField_b)


/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/



/* Router */
import Vue          from 'vue';
import Router       from 'vue-router';

//404
// import notFound         from '@/views/notFound';

//MAIN
import main             from '@/views/main';

//컨설팅 분야 
import purchase         from '@/views/consulting/purchase'
import purchase_b       from '@/views/consulting/purchase_b'
import assets         from '@/views/consulting/assets'
import assets_b       from '@/views/consulting/assets_b'
import restructure      from '@/views/consulting/restructure'
import restructure_b      from '@/views/consulting/restructure_b'
import strategy         from '@/views/consulting/strategy'
import strategy_b       from '@/views/consulting/strategy_b'
import collection       from '@/views/consulting/collection'
import collection_b     from '@/views/consulting/collection_b'
import payment          from '@/views/consulting/payment'
import payment_b        from '@/views/consulting/payment_b'
import policy           from '@/views/consulting/policy'
import policy_b         from '@/views/consulting/policy_b'
import conversion       from '@/views/consulting/conversion'
import conversion_b       from '@/views/consulting/conversion_b'
import certify         from '@/views/consulting/certify'
import certify_b       from '@/views/consulting/certify_b'
import hospital         from '@/views/consulting/hospital'
import hospital_b       from '@/views/consulting/hospital_b'

//회사소개
import introduce        from '@/views/company/introduce';
import tsi          from '@/views/company/tsi';
import network          from '@/views/company/network';

//세미나
import seminar         from '@/views/seminar/seminar';

//언론보도
import news             from '@/views/media/news';
import video            from '@/views/media/video';

//게시판
import boardView from '@/views/board/view';


import complete from '@/views/etc/complete';

Vue.use(Router)
const router = new Router({
    mode: 'history',
    routes: [
        //404
        {
            path: '*',
            redirect: '/404'
        },
        // {
        //     path: "/404",
        //     name: "notFound",
        //     component: notFound
        // },

        //MAIN
        {
            path: '/',
            name: main,
            component: main
        },{
            path: '/index.html',
            name: main,
            component: main
        },{
            path: '/main', 
            redirect : '/'
        },

        //컨설팅 분야 - 자사주매입 
        {
            path: '/consulting/purchase',
            name: purchase_b,
            component: purchase_b
        },{
            path: '/consulting/purchase_b',
            name: purchase,
            component: purchase
        },{
            path: '/:page/treasury-stock',
            redirect: '/consulting/purchase' 
        },{
            path: '/:page/treasury-stock/:id',
            redirect: '/consulting/purchase' 
        },
        //컨설팅 분야 - (가족법인활용)자산이전 
        {
            path: '/consulting/assets',
            name: assets_b,
            component: assets_b
        },{
            path: '/consulting/assets_b',
            name: assets,
            component: assets
        },{
            path: '/:page/treasury-stock',
            redirect: '/consulting/assets' 
        },{
            path: '/:page/treasury-stock/:id',
            redirect: '/consulting/assets' 
        },
        //컨설팅 분야 - 지배구조개선
        {
            path: '/consulting/restructure',
            name: restructure_b,
            component: restructure_b
        },{
            path: '/consulting/restructure_b',
            name: restructure,
            component: restructure
        },{
            path: '/:page/structure',
            redirect : '/consulting/restructure'
        },
        //컨설팅 분야 - 가업승계전략
        {
            path: '/consulting/strategy',
            name: strategy_b,
            component: strategy_b
        },{
            path: '/consulting/strategy_b',
            name: strategy,
            component: strategy
        },{
            path: '/:page/family-business',
            redirect : '/consulting/strategy'
        },{
            path: '/:page/family-business/:id',
            redirect : '/consulting/strategy'
        },
        //컨설팅 분야 - 차명주식회수 
        {
            path: '/consulting/collection',
            name: collection_b,
            component: collection_b
        },{
            path: '/consulting/collection_b',
            name: collection,
            component: collection
        },{
            path: '/:page/impersonate/:id',
            redirect: '/consulting/collection' 
        },
        //컨설팅 분야 - 가지급금 정리
        {
            path: '/consulting/payment',
            name: payment_b,
            component: payment_b
        },{
            path: '/consulting/payment_b',
            name: payment,
            component: payment
        },{
            path: '/:page/provisional-payment',
            redirect : '/consulting/payment'
        },{
            path: '/:page/provisional-payment/:id',
            redirect : '/consulting/payment'
        },
        //컨설팅 분야 - 배당정책
        {
            path: '/consulting/policy',
            name: policy_b,
            component: policy_b
        },{
            path: '/consulting/policy_b',
            name: policy,
            component: policy
        },{
            path: '/:page/dividend-policy',
            redirect : '/consulting/policy'
        },{
            path: '/:page/dividend-policy/:id',
            redirect : '/consulting/policy'
        },
        //컨설팅 분야 - 법인전환
        {
            path: '/consulting/conversion',
            name: conversion_b,
            component: conversion_b
        },{
            path: '/consulting/conversion_b',
            name: conversion,
            component: conversion
        },{
            path: '/:page/individual-business/:id',
            redirect : '/consulting/conversion'
        },
        //컨설팅 분야 - 인증/정책자금/경정청구 
        {
            path: '/consulting/certify',
            name: certify_b,
            component: certify_b
        },{
            path: '/consulting/certify_b',
            name: certify,
            component: certify
        },{
            path: '/:page/treasury-stock',
            redirect: '/consulting/certify' 
        },{
            path: '/:page/treasury-stock/:id',
            redirect: '/consulting/certify' 
        },
        //컨설팅 분야 - 병/의원컨설팅 
        {
            path: '/consulting/hospital',
            name: hospital_b,
            component: hospital_b
        },{
            path: '/consulting/hospital_b',
            name: hospital,
            component: hospital
        },{
            path: '/:page/treasury-stock',
            redirect: '/consulting/hospital' 
        },{
            path: '/:page/treasury-stock/:id',
            redirect: '/consulting/hospital' 
        },

        //회사소개 - 오시는길
        {
            path: '/company/introduce',
            name: introduce,
            component: introduce
        },{
            path: '/:page/contact',
            redirect : '/company/introduce'
        },{
            path: '/:page/intro',
            redirect: '/company/introduce' 
        },
        // 회사소개 - 세종TSI
        {
            path: '/company/tsi',
            name: tsi,
            component: tsi
        },
         // 회사소개 - 전문가 네트워크
        {
            path: '/company/network',
            name: network,
            component: network
        },{
            path : '/:page/specialist',
            redirect: '/company/network',
        },

        //세미나
        {
            path: '/seminar/seminar',
            name: seminar,
            component: seminar
        },{
            path: '/:page/seminar',
            redirect : '/seminar/seminar' 
        },{
            path: '/:page/seminar/view/:id',
            redirect : '/seminar/seminar'
        },

        //언론보도 - 언론보도
        {
            path: '/media/news',
            name: news,
            component: news
        },{
            path: '/:page/news',
            redirect: '/media/news',
        },{
            path : '/:page/report',
            redirect: '/media/news',
        },

        //언론보도 - 전문가 영상
        {
            path: '/media/video',
            name: video,
            component: video
        },

        //게시판
        {
            path: '/board/view',
            name: boardView,
            component: boardView
        },{
            path: '/:page/report/view/:id',
            redirect : to => ({
                path : '/board/view',
                query: { 
                    boardName : 'news',
                    id: to.params.id 
                },
            })
        },{
            path: '/:page/report/:id',
            redirect : to => ({
                path : '/board/view',
                query: { 
                    boardName : 'news',
                    id: to.params.id 
                },
            })
        },

        //상담신청완료
        {
            path: '/complete',
            name: complete,
            component: complete
        },
    ]
})

export default router



/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/