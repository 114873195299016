<template>
    <section id="consultingField" class="section">
        <div class="inner">
            <h3 class="tit_section">다른 컨설팅을 찾고 계신가요?</h3>
            <ul class="list_field">
                <li class="item_field">
                    <a class="link_field" href="/consulting/purchase">
                        <p class="tit">자사주 매입</p>
                        <p class="desc">법인이 자기 회사가 발행한<br>주식을 취득하여 보유하는 것으로,<br>자기주식 취득 시 장점과<br> 실행절차를 안내해드립니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
                <li class="item_field">
                    <a class="link_field" href="/consulting/assets">
                        <p class="tit">(가족법인활용)자산이전</p>
                        <p class="desc">가족 법인을 활용한 가업승계 또는 자산이전의 실제 적용 및 효과는 물론 관련 세법의 검토와 사례, 활용 접근법에 대해 안내해 드립니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
                <li class="item_field">
                    <a class="link_field" href="/consulting/restructure">
                        <p class="tit">지배구조 개선</p>
                        <p class="desc">비상장 중소기업 대표이사의 재무적,<br>비재무적인 리스크 해결을 위해<br>지배구조 개선의 중요성을<br>안내해드립니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
                <li class="item_field">
                    <a class="link_field" href="/consulting/strategy">
                        <p class="tit">가업승계 전략</p>
                        <p class="desc">기업이 주식이나  재산 등의 소유권과<br>경영권을 다음 세대에게 이전하는<br> 다양한 방법을 안내해 드립니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
                <li class="item_field">
                    <a class="link_field" href="/consulting/collection">
                        <p class="tit">차명주식회수 (=명의신탁)</p>
                        <p class="desc">실제 소유자가 다른 사람의<br> 명의를 빌려 보유한 주식으로<br>회수하는 방법을 안내해드립니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
                <li class="item_field">
                    <a class="link_field" href="/consulting/payment">
                        <p class="tit">가지급금 정리</p>
                        <p class="desc">가지급금은 부실 자산으로 직접적인<br>손실 외에 횡령의 문제도 될 수 있어<br>정확한 원인 파악 과 정리가<br>필요합니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
                <li class="item_field">
                    <a class="link_field" href="/consulting/policy">
                        <p class="tit">배당 정책</p>
                        <p class="desc">배당정책의 종류 및 활용방안과<br>배당정책 실행 시 절세효과가<br>무엇인지 안내해드립니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
                <li class="item_field">
                    <a class="link_field" href="/consulting/conversion">
                        <p class="tit">법인 전환</p>
                        <p class="desc">개인사업자 법인전환 검토 대상기업<br>조건과 법인으로 전환 후 세율, 세금<br>에 대한 정보를 안내해드립니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
                <li class="item_field">
                    <a class="link_field" href="/consulting/certify">
                        <p class="tit">인증/정책자금/경정청구</p>
                        <p class="desc">인증제도와 정책자금, 경정청구를 활용할 시 장점 및 종류, 유리할 수 있는 조건, 컨설팅의 필요성에 대해 안내해드립니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
                <li class="item_field">
                    <a class="link_field" href="/consulting/hospital">
                        <p class="tit">병/의원컨설팅</p>
                        <p class="desc">MSO(Management Service Organization)의 장점 및 기대효과, 설립 시 주요사항에 대해 안내해드립니다.</p>
                        <img class="ico_link" :src="imgPath+'ico_link.svg'" alt="">
                    </a>
                </li>
            </ul>
            <button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span></button>
        </div>
    </section>
</template>



<script>
import $ from 'jquery'

export default {
    name: 'consultingField',
    data() {
        return {
            imgPath: '/img/common/contents/consultingField/'
        }
    },
    mounted() {



        function getLastPath(data) {
            var rLastPath = /\/([a-zA-Z0-9._]+)(?:\?.*)?$/;
            return rLastPath.test(data) && RegExp.$1;
        }



        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



            var $wrap = $('#consultingField');

            //현재 페이지 is-active 추가
            $wrap.find('.item_field').each(function(){
                var $this = $(this);
                var href = $this.find('.link_field').attr('href');
                var lastPath = getLastPath(href);

                $this.addClass(lastPath);

                if (getLastPath(location.pathname) == lastPath) {
                    var $isActive = $this.addClass('is-active');

                    $isActive.find('.ico_link').remove();
                    $isActive.find('.desc').after('<img class="ico_link eye" src="/img/common/contents/consultingField/ico_eye.svg" alt="">')
                }
            });

            //무료상딤신청
            $wrap.find('.btn_cont').on('click', function(){
                $('header .toggleLayer.btn1').trigger('click');
            });



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    },
}
</script>



<style lang="scss">
    $imgPath : "/img/common/contents/consultingField/";

    #consultingField {
        padding-bottom:90px;
        .tit_section {
            position:relative;margin-bottom:55px;padding:90px 0 16px;font-size:30px;font-weight:500;line-height:1em;text-align:center;
            &::before {
                content:"";position:absolute;bottom:0;left:50%;width:90px;height:4px;background-color:#ff6815;transform:translateX(-50%);
            }
        }
    }

    #consultingField {
        .list_field {
            display:flex;flex-wrap:wrap;position:relative;margin:0 -8px 50px;
            &::after {
                content:"";position:absolute;bottom:75px;right:-75px;width:355px;height:170px;background-image:url(#{$imgPath}list_bg.png);
            }
        }
    }

    #consultingField {
        .item_field {
            width:25%;height:312px;padding:8px;
            .link_field {
                display:block;position:relative;width:100%;height:100%;padding:0 30px;
                &::after {
                    content:"";position:absolute;top:0;bottom:0;left:0;right:0;border:2px solid #e1e0e1;
                }
                &::before {
                    content:"";position:absolute;top:33px;left:33px;width:42px;height:42px;background-position-y:100%;
                }
                .tit {
                    padding:105px 0 50px;font-size:20px;color:#221e1f;line-height:1em;
                }
                .desc {
                    font-size:16px;color:#505056;
                }
                .ico_link {
                    position:absolute;top:30px;right:30px;height:25px;font-size:25px;
                    &.eye {
                        top:25px;height:34px;font-size:34px;
                    }
                }
                &:hover {
                    &::after {
                        border-color:#000;
                    }
                    &::before {
                        background-position-y:0;
                    }
                }
            }
            &.is-active {
                .link_field {
                    &::after {
                        border:6px solid #000;
                    }
                }
            }
        }
    }
    

    #consultingField {
        .item_field {
            opacity:0;transform:translateY(5%);transition:none;
        }
        &.appear {
            .item_field {
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        opacity:1;transform:translateY(0);transition:all 0.6s #{$i * 0.1}s ease-in-out;
                    }
                }
            }
        }
    }

    #consultingField {
        .item_field {
            $value:
                collection #fe5f03,
                purchase #35bb58,
                payment #ab4595,
                policy #0aaeaf,
                strategy #f47557,
                conversion #203059,
                restructure #2dddf2;
            @each $name, $color in $value {
                &.#{$name} {
                    .link_field {
                        &::before {
                            background-image:url(#{$imgPath}item_bg_#{$name}.png);
                        }
                        &:hover {
                            &::after {
                                border-color:#{$color};
                            }
                            .ico_link {
                                fill:#{$color};
                            }
                        }
                    }
                    &.is-active {
                        .link_field {
                            &::after {
                                border-color:#{$color};
                            }
                            &::before {
                                background-position-y:0;
                            }
                            .tit {
                                font-weight:bold;
                            }
                            .ico_link {
                                fill:#{$color};
                            }
                        }
                    }
                }
            }
        }
    }

    #consultingField {
        .btn_cont {
            @include slideBtn(235px,54px,#ff6815,18px);
            margin:0 auto;
        }
        &.hover {
            .btn_cont {
                @include slideBtnHover();
            }
        }
    }
</style>