<template>
    <section id="consultingField_b" class="section">
        <div class="inner">
            <h3 class="tit_section">다른 컨설팅을 찾고 계신가요?</h3>
            <swiper :options="swiperOption">
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg2.jpg)'">
                    <a class="link_field" href="/consulting/purchase">
                        <p class="tit">자사주 매입</p>
                        <p class="desc">법인이 자기 회사가 발행한 주식을 취득하여 보유하는 것으로, 자기주식 취득 시 장점과 실행절차를 안내해드립니다.</p>
                    </a>
                </swiper-slide>
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg8.jpg)'">
                    <a class="link_field" href="/consulting/assets">
                        <p class="tit">(가족법인활용)자산이전</p>
                        <p class="desc">가족 법인을 활용한 가업승계 또는 자산이전의 실제 적용 및 효과는 물론 관련 세법의 검토와 사례, 활용 접근법에 대해 안내해 드립니다.</p>
                    </a>
                </swiper-slide>
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg7.jpg)'">
                    <a class="link_field" href="/consulting/restructure">
                        <p class="tit">지배구조 개선</p>
                        <p class="desc">비상장 중소기업 대표이사의 재무적, 비재무적인 리스크 해결을 위해 지배구조 개선의 중요성을 안내해드립니다.</p>
                    </a>
                </swiper-slide>
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg5.jpg)'">
                    <a class="link_field" href="/consulting/strategy">
                        <p class="tit">가업승계 전략</p>
                        <p class="desc">기업이 주식이나  재산 등의 소유권과 경영권을 다음 세대에게 이전하는 다양한 방법을 안내해 드립니다.</p>
                    </a>
                </swiper-slide>
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg1.jpg)'">
                    <a class="link_field" href="/consulting/collection">
                        <p class="tit">차명주식회수 (=명의신탁)</p>
                        <p class="desc">실제 소유자가 다른 사람의 명의를 빌려 보유한 주식으로 회수하는 방법을 안내해드립니다.</p>
                    </a>
                </swiper-slide>
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg3.jpg)'">
                    <a class="link_field" href="/consulting/payment">
                        <p class="tit">가지급금 정리</p>
                        <p class="desc">가지급금은 부실 자산으로 직접적인 손실 외에 횡령의 문제도 될 수 있어 정확한 원인 파악 과 정리가 필요합니다.</p>
                    </a>
                </swiper-slide>
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg4.jpg)'">
                    <a class="link_field" href="/consulting/policy">
                        <p class="tit">배당 정책</p>
                        <p class="desc">배당정책의 종류 및 활용방안과 배당정책 실행 시 절세효과가 무엇인지 안내해드립니다.</p>
                    </a>
                </swiper-slide>
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg6.jpg)'">
                    <a class="link_field" href="/consulting/conversion">
                        <p class="tit">법인 전환</p>
                        <p class="desc">개인사업자 법인전환 검토 대상기업건과 법인으로 전환 후 세율, 세금 대한 정보를 안내해드립니다.</p>
                    </a>
                </swiper-slide>
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg9.jpg)'">
                    <a class="link_field" href="/consulting/certify">
                        <p class="tit">인증/정책자금/경정청구</p>
                        <p class="desc">인증제도와 정책자금, 경정청구를 활용할 시 장점 및 종류, 유리할 수 있는 조건, 컨설팅의 필요성에 대해 안내해드립니다.</p>
                    </a>
                </swiper-slide>
                <swiper-slide :style="'background-image:url('+imgPath+'slide_bg10.jpg)'">
                    <a class="link_field" href="/consulting/hospital">
                        <p class="tit">병/의원컨설팅</p>
                        <p class="desc">MSO(Management Service Organization)의 장점 및 기대효과, 설립 시 주요사항에 대해 안내해드립니다.</p>
                    </a>
                </swiper-slide>
            </swiper>
            <button class="btn_cont" type="button" v-on:click=" layer"><span>무료 컨설팅 상담신청</span></button>
        </div>
    </section>
</template>



<script>
import {swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    name: 'consultingField_b',
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            imgPath: '/img/common/contents/consultingField_b/',
            swiperOption: {
                autoplay: {
                    delay: 1,
                    disableOnInteraction : false,
                },
                slidesPerView: 'auto',
                spaceBetween: 30,
                grabCursor: true,
                mousewheelControl: true,
                keyboardControl: true,
                speed: 5000,
                loop: true,
                freeMode: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            }
        }
    },
    methods: {
        layer: function() {
            var layerBtn = document.querySelector('header .toggleLayer.btn1');
            layerBtn.click();
        }
    }
}
</script>



<style lang="scss">
    $imgPath : "/img/common/contents/consultingField_b/";

    #consultingField_b {
        padding: 70px 0 50px;
        .tit_section {
            position:relative; padding-bottom: 35px; font-size:36px;font-weight:bold;line-height:1em;text-align:center;
        }
    }

    #consultingField_b {
        .swiper-container {
            margin:0 -400px 80px;
            .swiper-wrapper {
                transition-timing-function:linear;
            }
        }
    }

    #consultingField_b {
        .swiper-slide {
            width:370px;height:305px;background-color:#666;background-size:cover;
            .link_field {
                display:block;width:100%;height:100%;padding:175px 30px 0;
                .tit {
                    margin-bottom:15px;font-size:24px;font-weight:bold;color:#fff;line-height:1em;text-shadow:0 0 3px rgba(0,0,0,0.8);
                }
                .desc {
                    font-size:16px;font-weight:500;color:#fff;line-height:1.4em;word-break:keep-all;
                }
            }
        }
    }

    #consultingField_b {
        .swiper-pagination {
            bottom:90px;left:50%;transform:translateX(-50%);
            .swiper-pagination-bullet {
                width:14px;height:14px;margin:0 5px;border:1px solid #a8a8a8;background-color:transparent;
            }
            .swiper-pagination-bullet-active {
                border-color:transparent;background-color:#393c45;
            }
        }
    }

    #consultingField_b {
        .btn_cont {
            @include slideBtn(235px,54px,#ff6815,18px);
            margin:0 auto;
        }
        &.hover {
            .btn_cont {
                @include slideBtnHover();
            }
        }
    }
</style>