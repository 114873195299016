<template>
<!-- main -->
<main id="container">
    <!-- 바로가기 -->
    <nav id="nav_container">
        <div class="list_nav">
            <a class="link_nav" data-target="#visual"><i></i><span>Main</span></a>
            <a class="link_nav" data-target="#example"><i></i><span>컨설팅 사례</span></a>
            <a class="link_nav" data-target="#effect"><i></i><span>배당정책 효과</span></a>
            <a class="link_nav" data-target="#howto"><i></i><span>배당정책 종류 및 활용</span></a>
            <a class="link_nav" data-target="#consultingProcess_b"><i></i><span>컨설팅 프로세스</span></a>
            <a class="link_nav" data-target="#networkTab"><i></i><span>전문가 네트워크</span></a>
            <a class="link_nav" data-target="#consulting"><i></i><span>무료 상담신청</span></a>
            <a class="link_nav" data-target="#consultingField_b"><i></i><span>바로가기</span></a>
        </div>
    </nav>
    <!-- //바로가기 -->

     <!-- 메인 -->
     <section id="visual" data-type="b">
        <div class="inner">
            <h3 class="tit_section">배당정책</h3>
            <p class="desc_section">
                배당이란 기업이 영업활동을 통해 벌어들인 이익 중 일부를 해당 기업의 주식으로 보유하고 있는 주주들에게<br>
                소유 지분에 따라 나눠주는 것을 말합니다. 이런 배당을 소유와 경영이 <mark>분리된 주식회사에서</mark><br>
                <mark>소유주가 이익금을 회수하는 주요 방법이자 절세를 위한 효과적인 도구</mark>이므로 이를 적극적으로 활용할 필요가 있습니다.
            </p>
            <button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span><img class="ico" src="" data-json-path="/image/svg/arr_right02.svg"></button>
            <div class="list_visual">
                <button class="btn_visual" data-target="#example" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img01.png" alt="">
                    <p class="tit">컨설팅 사례</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#effect" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img02.png" alt="">
                    <p class="tit">배당정책 효과</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#howto" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img03.png" alt="">
                    <p class="tit">배당정책 종류 및 활용</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#consultingProcess_b" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img04.png" alt="">
                    <p class="tit">컨설팅 프로세스</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#consulting" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img05.png" alt="">
                    <p class="tit">무료 상담신청</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
            </div>
        </div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="section" data-type="b">
        <div class="inner">
            <h3 class="tit_section">배당정책 컨설팅 사례</h3>
            <div class="list_talk">
                <div class="item_talk left">
                    <p class="tit_talk color-ffffff" style="color:#fff;">의뢰인</p>
                    <img class="profile" src="/img/consulting/common/example_talk_profile_client1.png" alt="">
                    <div class="tooltip">
                        <p class="tit">문제점</p>
                        <p>가업승계 전 소액주주의 지분 정리와 자녀, 손자들<br> 명의로 주식 이동을 희망합니다.</p>
                    </div>
                    <button class="videoLayer" data-src="https://www.youtube.com/embed/8OqQSUAW2_A" data-title="법인의 배당정책 활성화는 필수" data-type="youtube" type="button" title="영상재생">
                        <img class="thumb" src="https://img.youtube.com/vi/8OqQSUAW2_A/maxresdefault.jpg" alt="스크린샷">
                    </button>
                </div>
                <div class="item_talk right" style="min-height:405px; margin-top:0;">
                    <div class="profile">
                        <img src="/img/consulting/collection_b/example_talk_profile_professional1.png" alt="">
                        <span class="profile_name">컨설팅 전문가</span>
                    </div>
                    <div class="tooltip" style="margin-top: 65px;">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>대표이사가 보유한 주식의 일부를 비과세 범위내에서 3명의<br>손주들에게 증여하였으며 자녀들과 손주들의 주식양수대금은<br>차등배당으로 해결하였습니다.</li>
                            <li>주식가치평가를 통해 시가 산정 후 주식양수도 계약을<br>체결하였습니다.</li>
                            <li>매매당사자의 관계가 특수관계자 여부에 따라 적합한 범위 내 주식<br>가격 인하가 가능했습니다.</li>
                            <li>지원본부 소속의 전문가 그룹인 세무사, 변호사와 팀을 이루어<br>증여세, 양도세, 차등배당 소득세 신고 및 납부하였습니다.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 배당정책 효과 -->
    <section id="effect" class="section">
        <div class="inner">
            <h3 class="tit_section">배당정책 효과</h3>
            <div class="cons_info_area-round">
                <p class="desc_cons">배당은 소유와 경영이 분리된 주식회사에서 <strong>소유주가 이익금을 회수하는 주요 방법이자 절세를 위한 효과적인 도구</strong>이므로<br> 이를 적극적으로 활용할 필요가 있습니다.<br> 배당을 활용하면 <strong>법인 CEO의 소득을 분산</strong>할 수 있고 추후에 <strong>고율의 상속세도 피할 수</strong> 있습니다.<br> 배당정책을 수립하여 체계적으로 실행하는 경우 절세효과는 다음과 같습니다.</p>
                <ul class="list_box cons_info_area">
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'policy_img01.png'" alt="">
                        <div class="desc_box">
                            <p class="tit">소득분산 및 자금출처 확보</p>
                            <p class="desc">지분설계를 통해 소득 분산이<br> 가능하게 되고, 이를 통해 배우자나<br> 자녀의 소득출처를 명확하게 할 수 있다.</p>
                        </div>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'policy_img02.png'" alt="">
                        <div class="desc_box">      
                            <p class="tit">절세효과</p>
                            <p class="desc">급여나 상여로만 법인자금을 개인화 하는것<br> 보다 분리과세가 가능한 배당을 활용하면<br> 절세효과를 가져올 수 있다.</p>
                        </div>
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:25px" :src="imgPath+'policy_img03.png'" alt="">
                        <div class="desc_box">
                            <p class="tit">중간배당 가능</p>
                            <p class="desc">정관에 관련 내용을 명시한 후 이사회<br> 결의를 거치면 배당가능 이익 내에서<br> 정기배당 외에 1회 더 배당이 가능하게 된다.</p>
                        </div>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'policy_img04.png'" alt="">
                        <div class="desc_box">
                            <p class="tit">가업승계와 상속플랜으로<br> 연결 가능</p>
                        </div>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'policy_img05.png'" alt="">
                        <div class="desc_box">
                            <p class="tit">가지급금정리 솔루션으로 적용 가능</p>
                        </div>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'policy_img06.png'" alt="">
                        <div class="desc_box">
                            <p class="tit">비상장주식의 주가관리 가능</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- //배당정책 효과 -->

    <!-- 종류 및 활용 -->
    <section id="howto" class="section">
        <div class="inner">
            <h3 class="tit_section">배당정책 종류 및 활용</h3>
            <div class="cons_info_area-round border">
                <p class="tit_cons">1. 적극적인 배당정책 종류 </p>
                <ul class="thumb_list cons_info_area">
                    <li class="round_thumb">
                         <img :src="imgPath+'policy_img07.png'" alt="">
                         <p class="tit">차등배당 플랜</p>
                    </li>
                    <li class="round_thumb">
                         <img :src="imgPath+'policy_img08.png'" alt="">
                         <p class="tit">중간배당 플랜</p>
                    </li>
                    <li class="round_thumb">
                         <img :src="imgPath+'policy_img09.png'" alt="">
                         <p class="tit">Step-Up배당 플랜</p>
                    </li>
                    <li class="round_thumb">
                         <img :src="imgPath+'policy_img10.png'" alt="">
                         <p class="tit">명의신탁해지 배당</p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area-round border">
                <p class="tit_cons mb-50">2. 배당정책 활용 목적</p>
                <ul class="orange_panel cons_info_area">
                    <li class="orange_box">
                        <p class="desc">법인자금 회수</p>
                    </li>
                    <li class="orange_box">
                        <p class="desc">가업승계를 위한<br> 사전준비</p>
                    </li>
                    <li class="orange_box">
                        <p class="desc">상속 및 증여세,<br> 양도소득세<br> 절세전략의 일환</p>
                    </li>
                    <li class="orange_box">
                        <p class="desc">주가관리 목적</p>
                    </li>
                    <li class="orange_box">
                        <p class="desc">대표의<br> 경영리스크 분산</p>
                    </li>
                    <li class="orange_box">
                        <p class="desc">주주의 <br> 배당압력 해소</p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area-round border">
                <div class="box_checkpoint cons_info_area">
                    <p class="tit"><i><img src="/img/consulting/common/cons_info_area_checkpoint.png" alt=""></i> 배당정책 활용 시 <strong> Check point</strong></p>
                    <div class="list_desc">
                        <p class="desc"><i>Check&nbsp;</i>배당정책 활용 시 법에서 정한 요건을 충족하고 있는지 고려해야 합니다.</p>
                        <p class="desc"><i>Check&nbsp;</i>적법한 규정과 절차 준수 여부를 확인해야 합니다.</p>
                        <p class="desc"><i>Check&nbsp;</i>지분설계, 절세, 배당가능금액 등의 철저한 분석 여부를 확인해야 합니다.</p>
                        <p class="desc"><i>Check&nbsp;</i>법인정관에 배당 관련 사항 명시 여부를 확인해야 합니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //종류 및 활용 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess_b data-list-idx="1" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="section"></section>
    <!-- //무료 컨설팅 상담신청 -->

    <!-- 컨설팅 분야 바로가기 -->
    <consultingField_b />
    <!-- //컨설팅 분야 바로가기 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/policy_b/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/policy_b.scss';
</style>