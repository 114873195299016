<template>
<!-- main -->
<main id="container">
	<!-- section 위치표시 및 바로가기 -->
	<nav id="nav_container">
		<div class="list_nav">
			<a class="link_nav" data-target="#visual"><i></i><span>Main</span></a>
			<a class="link_nav" data-target="#system"><i></i><span>인증제도</span></a>
			<a class="link_nav" data-target="#funds"><i></i><span>정부지원사업(정책자금)</span></a>
			<a class="link_nav" data-target="#correction"><i></i><span>경정청구</span></a>
			<a class="link_nav" data-target="#consultingProcess_b"><i></i><span>컨설팅 프로세스</span></a>
			<a class="link_nav" data-target="#networkTab"><i></i><span>전문가 네트워크</span></a>
			<a class="link_nav" data-target="#consulting"><i></i><span>무료 상담신청</span></a>
			<a class="link_nav" data-target="#consultingField_b"><i></i><span>바로가기</span></a>
		</div>
	</nav>
	<!-- //section 위치표시 및 바로가기 -->

	<!-- 메인 -->
	<section id="visual" data-type="b">
		<div class="inner">
			<h3 class="tit_section">인증 / 정부지원사업(정책자금) / 경정청구</h3>
			<p class="desc_section">
				기업의 여러 이해관계 <mark>기관이 기업에 주는 우대제도,</mark><br>
				정부가 기업에게 지원할 목적으로 <mark>정책자금을 집행하는 사업</mark>, 사업자가 세금을 더 납부했거나<br>
				잘 못 납부할 경우 <mark>국세청에 돌려달라고 요청하는 제도</mark>에 대해 알아봅니다.
			</p>
			<button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span><img class="ico" src="" data-json-path="/image/svg/arr_right02.svg"></button>
			<div class="list_visual">
				<button class="btn_visual" data-target="#system" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img01.png" alt="">
					<p class="tit">인증제도</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#funds" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img02.png" alt="">
					<p class="tit">정부지원사업(정책자금)</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#correction" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img03.png" alt="">
					<p class="tit">경정청구</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#consultingProcess_b" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img04.png" alt="">
					<p class="tit">컨설팅 프로세스</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#consulting" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img05.png" alt="">
					<p class="tit">무료 컨설팅 상담신청</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
			</div>
		</div>
	</section>
	<!-- //메인 -->

	<!-- 인증제도 -->
	<section id="system" class="section" data-type="b">
		<div class="inner">
			<h3 class="tit_section">인증제도</h3>
			<div class="contents">
				<div class="box">
					<strong>인증의 정의</strong>
					<p>기업활동을 영위하는 과정에서 여러 이해관계 기관의 공식적인 인정요인(R&D, 기술, 사업성 등)을 입증하고 사실관계를 확인하여 기업에 주는 우대제도</p>
				</div>
				<div class="box">
					<strong>인증의 범위</strong>
					<ol>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/system_img01.png" alt="">
							</div>
							<div class="info">
								<strong>소극적 범위</strong>
								<span>개별 기관의 업무상 필요한 거래 절차 및 확인 절차</span>
								<span>각종 기관 및 산하기관의 인증서</span>
							</div>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/system_img02.png" alt="">
							</div>
							<div class="info">
								<strong>적극적 범위</strong>
								<span>기업의 성장을 위한 상호연계성 확인 및 연결 기반 마련</span>
								<span>인증을 활용한 사업규모/범위 확대 및 신사업 모색</span>
							</div>
						</li>
					</ol>
				</div>
				<div class="box">
					<strong>인증제도 활용 시 장점</strong>
					<div class="img">
						<img src="/img/consulting/certify_b/system_img03.png" alt="">
					</div>
				</div>
				<div class="box">
					<strong>주요 인증제도</strong>
					<ul>
						<li>
							<strong>기업부설연구소</strong>
							<img src="/img/consulting/certify_b/system_img04.png" alt="">
						</li>
						<li>
							<strong>메인/이노비즈</strong>
							<img src="/img/consulting/certify_b/system_img05.png" alt="">
						</li>
						<li>
							<strong>벤처기업</strong>
							<img src="/img/consulting/certify_b/system_img06.png" alt="">
						</li>
						<li>
							<strong>뿌리기업확인</strong>
							<img src="/img/consulting/certify_b/system_img07.png" alt="">
						</li>
						<li>
							<strong>병역특례기업</strong>
							<img src="/img/consulting/certify_b/system_img08.png" alt="">
						</li>
						<li>
							<strong>정부 R&D사업</strong>
							<img src="/img/consulting/certify_b/system_img09.png" alt="">
						</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<!-- //인증제도 -->

	<!-- 정부지원사업(정책자금) -->
	<section id="funds" class="section">
		<div class="inner">
			<h3 class="tit_section">정부지원사업(정책자금)</h3>
			<div class="contents">
				<div class="box">
					<strong>정부지원사업(정책자금)의 정의</strong>
					<p>정부가 기업이나 단체, 개인을 지원 할 목적으로 정책자금을 집행하는 사업</p>
				</div>
				<div class="box">
					<strong>정책자금의 종류</strong>
					<ol>
						<li>
							<strong>운전자금</strong>
							<div class="info">
								<span>기업에 필요한 <b>원자재의 구매, 생산,</b><br><b>판매 활동</b>에 소요되는 자금을<br>저금리로 대출해주는 자금</span>
								<span>중소벤처기업진흥공단,<br>소상공인시장진흥공단, 기술보증기금,<br>신용보증기금, 신용보증재단 등</span>
							</div>
							<img src="/img/consulting/certify_b/funds_img01.png" alt="">
						</li>
						<li>
							<strong>시설자금</strong>
							<div class="info">
								기업에 필요한 <b>기계설비, 공장설립,</b><br><b>토지구입 등 시설, 설비 투자 및 구매</b>에<br>소요되는 자금을 저금리로 대출해<br>주는 자금
							</div>
							<img src="/img/consulting/certify_b/funds_img02.png" alt="">
						</li>
						<li>
							<strong>연구과제(R&D)</strong>
							<div class="info">
								<b>정부과제사업에 선정된 기업을 대상</b>으로<br>기술 또는 신용을 담보로 지원되는 자금
							</div>
							<img src="/img/consulting/certify_b/funds_img03.png" alt="">
						</li>
						<li>
							<strong>고용지원금</strong>
							<div class="info">
								<b>고용창출, 고용안정, 고용유지 등</b><br><b>근로자의 고용</b>과 관련한 인건비 등을<br>지원하는 장려금
							</div>
							<img src="/img/consulting/certify_b/funds_img04.png" alt="">
						</li>
						<li>
							<strong>사업화 패키지</strong>
							<div class="info">
								<span>기업창업 아이디어를 보유한<br><b>예비창업자의 성공창업을 위해</b> 사업화<br>자금, 비즈니스모델 고도화 및 시제품<br>제작 등을 <b>지원</b></span>
								<span>유망 창업아이템 및 고급기술을<br>보유한 창업 3년 이내 창업기업에<br>사업화 자금 및 초기창업프로그램을<br>지원하여 기업의 안정화 및 성장을<br>지원 (창업진흥원)</span>
							</div>
							<img src="/img/consulting/certify_b/funds_img05.png" alt="">
						</li>
						<li>
							<strong>수출 바우처</strong>
							<div class="info">
								수출 역량을 키우고 싶은 기업들이<br><b>수출지원 서비스를 선택하여 활용</b>할 수<br>있도록 보조금을 <b>바우처 형태로 지원</b>
							</div>
							<img src="/img/consulting/certify_b/funds_img06.png" alt="">
						</li>
					</ol>
				</div>
			</div>
		</div>
	</section>
	<!-- //정부지원사업(정책자금) -->

	<!-- 경정청구 -->
	<section id="correction" class="section">
		<div class="inner">
			<h3 class="tit_section">경정청구</h3>
			<div class="contents">
				<div class="box">
					<strong>경정청구의 정의</strong>
					<p>국세기본법 제45조의 2에 따라 납세자(사업자)가 세금을 더 납부했거나 잘 못 납부한 경우 이를 국세청에 돌려달라고 요청하는 제도</p>
				</div>
				<div class="box">
					<strong>경정청구의 주요 공제항목</strong>
					<ol>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img01.png" alt="">
							</div>
							<div class="info">
								고용 지원금, 고용 관련<br>세액공제
							</div>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img02.png" alt="">
							</div>
							<div class="info">
								사회보험료<br>세액공제
							</div>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img03.png" alt="">
							</div>
							<div class="info">
								통합 투자<br>세액공제
							</div>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img04.png" alt="">
							</div>
							<div class="info">
								연구 인력 개발비<br>세액공제
							</div>
						</li>
					</ol>
				</div>
				<div class="box">
					<strong>경정청구 시 유리할 수 있는 조건</strong>
					<div class="img_wrap">
						<strong>경정청구</strong>
						<dl>
							<dd>
								<div class="thumb">
									<img src="/img/consulting/certify_b/correction_img05.png" alt="">
								</div>
								<div class="info">
									직전 5년 전부터 매년 고용인원 증가
								</div>
							</dd>
							<dd>
								<div class="thumb">
									<img src="/img/consulting/certify_b/correction_img06.png" alt="">
								</div>
								<div class="info">
									직전 5년 전부터 매년 고용인원 증가
								</div>
							</dd>
							<dd>
								<div class="thumb">
									<img src="/img/consulting/certify_b/correction_img07.png" alt="">
								</div>
								<div class="info">
									직전 5년 전부터 매년 고용인원 증가
								</div>
							</dd>
						</dl>
						<em>
							* 단, 소비성 서비스업 제외(호텔업 및 여관업, 주점업, 오락·유흥 등을 목적으로 하는 사업)<br>
							* 관광진흥법에  따른 관광숙박업, 외국인 전용 유흥음식점업, 관광유흥음식점업은 가능
						</em>
					</div>
				</div>
				<div class="box">
					<strong>경정청구 컨설팅의 필요성</strong>
					<ul>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img08.png" alt="">
							</div>
							<div class="info">
								지속적인 세법 변경
							</div>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img09.png" alt="">
							</div>
							<div class="info">
								세제혜택에 대한<br>공제 적용 제한
							</div>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img10.png" alt="">
							</div>
							<div class="info">
								조세특례제한법의<br>내용이 광범위(140개 이상)
							</div>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img11.png" alt="">
							</div>
							<div class="info">
								비용증가나 순자산<br>감소 없이 절세
							</div>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img12.png" alt="">
							</div>
							<div class="info">
								최대 10년간 이월공제<br>및 세금 절세
							</div>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img13.png" alt="">
							</div>
							<div class="info">
								환급세액 발생시<br>기업 유동성에 도움
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<!-- //경정청구 -->

	<!-- 컨설팅 프로세스 -->
	<consultingProcess_b data-list-idx="1" data-title="컨설팅 프로세스" />
	<!-- //컨설팅 프로세스 -->

	<!-- 전문가 네트워크 -->
	<networkTab class="is-event-none" />
	<!-- //전문가 네트워크 -->

	<!-- 무료 컨설팅 상담신청 -->
	<section id="consulting" class="section"></section>
	<!-- //무료 컨설팅 상담신청 -->

	<!-- 컨설팅 분야 바로가기 -->
	<consultingField_b />
	<!-- //컨설팅 분야 바로가기 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
import $ from 'jquery'

export default {
	mounted() {
		$(function(){ // DOCUMENT READY...
		/*
		■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
		*/(function(){




		})();/*
		■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
		*/});// DOCUMENT READY...
	}
}
</script>



<style scoped lang="scss">
	@import '@/assets/scss/consulting/certify_b.scss';
</style>