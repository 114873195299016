<template>
<!-- main -->
<main id="container">
    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <h3 class="tit_section">세무법인 세종TSI는 매경경영지원본부와 제휴된 업체로 법인 및 고액자산가들에 대한<br>경영&자산관리 컨설팅을 제공하고 있는 세무법인 입니다.</h3>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 인물정보 -->
    <section id="networkList">
        <div class="inner">
            <p class="tit_section"><strong>세무법인 세종TSI</strong> 절세 전략 연구 개발(상속, 증여, 법인세 등)</p>
            <div class="ready">
                <div class="hourglass">
                    <div class="tit"><strong>프로필 준비중</strong> 입니다.</div>
                    <img class="ico" :src="imgPath+'networkList_hourglass.png'" alt="">
                    <i class="ico_shadow"></i>
                </div>
                <div class="particle_circle" style="bottom:20px;left:150px"></div>
                <div class="particle_circle" style="top:70px;right:165px"></div>
                <div class="particle_circle-border" style="bottom:60px;left:95px"></div>
            </div>            
            <ul class="list_member">
                <li class="item_member">
                    <div class="inner_item">
                        <p class="tit" data-type="">
                            <span class="role"></span>
                            <span class="name"></span>
                        </p>
                        <button class="btn_more" type="button">
                            <img class="ico" src="" data-json-path="/image/svg/plus03.svg" alt="">
                        </button>
                        <div class="thumb">
                            <img src="" alt="">
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
   <!-- //인물정보 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
import $ from 'jquery'

export default {
    data() {
        return {
            imgPath: '/img/company/tsi/'
        }
    },
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){




        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/company/tsi.scss';
</style>