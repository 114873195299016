<template>
<!-- main -->
<main id="container">
    <!-- 바로가기 -->
    <nav id="nav_container">
        <div class="list_nav">
            <a class="link_nav" data-target="#visual"><i></i><span>Main</span></a>
            <a class="link_nav" data-target="#example"><i></i><span>컨설팅 사례</span></a>
            <a class="link_nav" data-target="#effect"><i></i><span>배당정책 효과</span></a>
            <a class="link_nav" data-target="#howto"><i></i><span>배당정책 종류 및 활용</span></a>
            <a class="link_nav" data-target="#consultingProcess"><i></i><span>컨설팅 프로세스</span></a>
            <a class="link_nav" data-target="#networkTab"><i></i><span>전문가 네트워크</span></a>
            <a class="link_nav" data-target="#consulting"><i></i><span>무료 상담신청</span></a>
            <a class="link_nav" data-target="#consultingField"><i></i><span>바로가기</span></a>
        </div>
    </nav>
    <!-- //바로가기 -->

    <!-- 메인 -->
    <section id="visual" data-type="a">
        <div class="inner">
            <h3 class="tit_section">배당정책 </h3>
            <p class="desc_section">
                배당이란 <mark>기업이 영업활동을 통해 벌어들인 이익 중 일부를 해당 기업의 주식으로 보유하고 있는 주주들에게</mark><br>
                <mark>소유 지분에 따라 나눠주는 것</mark>을 말합니다. 이런 배당을 소유와 경영이 분리된 <mark>주식회사에서 소유주가 이익금을</mark><br>
                <mark>회수하는 주요 방법이자 절세를 위한 효과적인 도구</mark>이므로 이를 적극적으로 활용할 필요가 있습니다.
            </p>
            <button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span></button>
            <div class="list_visual">
                <button class="btn_visual" data-target="#example" type="button">
                    <img class="ico" :src="imgPath+'visual_ico1.png'" alt="">
                    <p class="tit">컨설팅 사례</p>
                    <p class="desc">전문가 컨설팅 사례를<br>영상으로 확인해보세요!</p>
                </button>
                <button class="btn_visual" data-target="#effect" type="button">
                    <img class="ico" :src="imgPath+'visual_ico2.png'" alt="">
                    <p class="tit">배당정책 효과</p>
                    <p class="desc">배당정책을 절세효과를<br> 확인하세요! </p>
                </button>
                <button class="btn_visual" data-target="#howto" type="button">
                    <img class="ico" :src="imgPath+'visual_ico3.png'" alt="">
                    <p class="tit">배당정책 종류 및 활용</p>
                    <p class="desc">배당의 전략과 전술을<br> 안내해드립니다.</p>
                </button>
                <button class="btn_visual" data-target="#consultingProcess" type="button">
                    <img class="ico" :src="imgPath+'visual_ico4.png'" alt="">
                    <p class="tit">컨설팅 프로세스</p>
                    <p class="desc">배당정책 절차<br> 확인!</p>
                </button>
                <button class="btn_visual" data-target="#consulting" type="button">
                    <img class="ico" :src="imgPath+'visual_ico5.png'" alt="">
                    <p class="tit">무료 상담신청</p>
                    <p class="desc">이제 고민은 그만!<br>전문가가 해결해드립니다. </p>
                </button>
            </div>
            <div class="particle_circle" style="bottom:15px;right:450px"></div>
            <div class="particle_circle-border" style="top:-130px;right:470px"></div>
        </div>
        <div class="particle_circle-double" style="bottom:-170px;left:80px;width:270px;height:270px"></div>
        <div class="particle_circle-double" style="top:10px;right:-120px;width:290px;height:290px"></div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="section" data-type="a">
        <div class="inner">
            <h3 class="tit_section">배당정책 컨설팅 사례 </h3>
            <button class="videoLayer" data-src="https://www.youtube.com/embed/R-wwYrF-4b0" data-title="중소기업의 주식배당" data-type="youtube" type="button" title="영상재생">
                <img class="thumb" src="https://img.youtube.com/vi/R-wwYrF-4b0/maxresdefault.jpg" alt="스크린샷">
                <div class="particle_circle" style="top:-40px;left:-30px;width:75px;height:75px"></div>
                <div class="particle_circle-border" style="bottom:-20px;right:-90px;width:66px;height:66px"></div>
            </button>
            <div class="list_talk">
                <div class="item_talk left">
                    <p class="tit_talk">의뢰인</p>
                    <img class="profile" src="/img/consulting/collection/example_talk_profile_client1.jpg" alt="">
                    <div class="tooltip">
                        <p class="tit">컨설팅 문의 </p>
                        <p>가업승계 전 소액주주의 지분 정리와 자녀, 손자들 명의로 주식 이동을<br>희망합니다.</p>
                    </div>
                </div>
                <div class="item_talk right">
                    <p class="tit_talk">컨설팅 전문가</p>
                    <img class="profile" :src="imgPath+'example_talk_profile_professional1.jpg'" alt="">
                    <div class="tooltip">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>대표이사가 보유한 주식의 일부를 비과세 범위내에서 3명의<br>손주들에게 증여하였으며 자녀들과 손주들의 주식양수대금은<br>차등배당으로 해결하였습니다.</li>
                            <li>주식가치평가를 통해 시가 산정 후 주식양수도 계약을<br>체결하였습니다.</li>
                            <li>매매당사자의 관계가 특수관계자 여부에 따라 적합한 범위 내 주식<br>가격 인하가 가능했습니다.</li>
                            <li>지원본부 소속의 전문가 그룹인 세무사, 변호사와 팀을 이루어<br>증여세, 양도세, 차등배당 소득세 신고 및 납부하였습니다.</li>
                        </ol>
                    </div>
                </div>
                <img class="bg" src="/img/consulting/collection/example_talk_bg.png" alt="">
            </div>
        </div>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 배당정책 효과 -->
    <section id="effect" class="section">
        <div class="inner">
            <h3 class="tit_section">배당정책 효과</h3>
            <div class="cons_info_area area1">
                <p class="desc_cons">배당은 소유와 경영이 분리된 주식회사에서 <strong>소유주가 이익금을 회수하는 주요 방법이자 절세를 위한 효과적인 도구</strong>이므로<br> 이를 적극적으로 활용할 필요가 있습니다.<br> 배당을 활용하면 <strong>법인 CEO의 소득을 분산</strong>할 수 있고 추후에 <strong>고율의 상속세도 피할 수</strong> 있습니다.<br> 배당정책을 수립하여 체계적으로 실행하는 경우 절세효과는 다음과 같습니다.</p>
                <ul class="list_box">
                    <li class="item_box">
                        <img class="illust" style="top:45px" :src="imgPath+'effect_illust1.png'" alt="">
                        <p class="tit">소득분산 및 자금출처 확보</p>
                        <p class="desc">지분설계를 통해 소득 분산이 가능하게 되고,<br> 이를 통해 배우자나 자녀의 소득출처를<br> 명확하게 할 수 있다.</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:45px" :src="imgPath+'effect_illust2.png'" alt="">
                        <p class="tit">절세효과</p>
                        <p class="desc">급여나 상여로만 법인자금을<br> 개인화 하는것 보다 분리과세가 가능한<br> 배당을 활용하면 절세효과를 가져올 수 있다.</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:25px" :src="imgPath+'effect_illust3.png'" alt="">
                        <p class="tit">중간배당 가능</p>
                        <p class="desc">정관에 관련 내용을 명시한 후<br> 이사회 결의를 거치면 배당가능 이익 내에서<br> 정기배당 외에 1회 더 배당이 가능하게 된다.</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:45px" :src="imgPath+'effect_illust4.png'" alt="">
                        <p class="tit">가업승계와 상속플랜으로<br> 연결 가능</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:45px" :src="imgPath+'effect_illust5.png'" alt="">
                        <p class="tit">가지급금정리 솔루션으로<br> 적용 가능</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:25px" :src="imgPath+'effect_illust6.png'" alt="">
                        <p class="tit">비상장주식의 주가관리 가능</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- //배당정책 효과 -->

    <!-- 종류 및 활용 -->
    <section id="howto" class="section">
        <div class="inner">
            <h3 class="tit_section">배당정책 종류 및 활용</h3>
            <div class="cons_info_area area1">
                <p class="tit_cons">1. 적극적인 배당정책 종류 </p>
                <ul class="list_info">
                    <li class="item_info">
                        <img :src="imgPath+'howto_illust1.png'" alt="">
                        <p class="tit">차등배당 플랜</p>
                    </li>
                    <li class="item_info">
                        <img :src="imgPath+'howto_illust2.png'" alt="">
                        <p class="tit">중간배당 플랜</p>
                    </li>
                    <li class="item_info">
                        <img :src="imgPath+'howto_illust3.png'" alt="">
                        <p class="tit">Step-Up배당 플랜</p>
                    </li>
                    <li class="item_info">
                        <img :src="imgPath+'howto_illust4.png'" alt="">
                        <p class="tit">명의신탁해지 배당</p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area">
                <p class="tit_cons mb-50">2. 배당정책 활용 목적</p>
                <ul class="list_graph">
                    <li class="item_graph">
                        <img :src="imgPath+'howto_graph1.png'" alt="">
                        <p class="tit">법인자금 회수</p>
                    </li>
                    <li class="item_graph">
                        <img :src="imgPath+'howto_graph2.png'" alt="">
                        <p class="tit">가업승계를 위한<br> 사전준비</p>
                    </li>
                    <li class="item_graph">
                        <img :src="imgPath+'howto_graph3.png'" alt="">
                        <p class="tit">상속 및 증여세,<br> 양도소득세<br> 절세전략의 일환</p>
                    </li>
                    <li class="item_graph">
                        <img :src="imgPath+'howto_graph4.png'" alt="">
                        <p class="tit">주가관리 목적</p>
                    </li>
                    <li class="item_graph">
                        <img :src="imgPath+'howto_graph5.png'" alt="">
                        <p class="tit">대표의<br> 경영리스크 분산</p>
                    </li>
                    <li class="item_graph">
                        <img :src="imgPath+'howto_graph6.png'" alt="">
                        <p class="tit">주주의 <br> 배당압력 해소</p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area mb-65">
                <div class="box_checkpoint">
                    <p class="tit">배당정책 활용 시 <strong><img class="ico" src="" data-json-path="/image/svg/check01.svg">체크 포인트</strong></p>
                    <div class="list_desc">
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">배당정책 활용 시 법에서 정한 요건을 충족하고 있는지 고려해야 합니다.</p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">적법한 규정과 절차 준수 여부를 확인해야 합니다.</p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">지분설계, 절세, 배당가능금액 등의 철저한 분석 여부를 확인해야 합니다.</p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">법인정관에 배당 관련 사항 명시 여부를 확인해야 합니다.</p>
                    </div>
                    <img class="clip" src="/img/common/ico/clip.png" alt="">
                    <img class="illust" :src="imgPath+'howto_checkpoint_illust.png'" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- //종류 및 활용 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="1" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="section"></section>
    <!-- //무료 컨설팅 상담신청 -->

    <!-- 컨설팅 분야 바로가기 -->
    <consultingField />
    <!-- //컨설팅 분야 바로가기 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/policy/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/policy.scss';
</style>