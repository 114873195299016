<template>
    <!-- main -->
    <main id="container">
        <section id="visual" class="section">
            <div class="bg_list">
                <div class="bg">
                    <div class="content" style="background-image: url(/img/main/visual07.jpg);"></div>
                </div>
            </div>
            <div class="inner">
                <div class="title_img">
                    <img :src="imgPath + 'visual_title.png'" />
                </div>
                <div class="tit">
                    <p><span>국내 최고의 전문가와 함께하는 맞춤 솔루션</span></p>
                    <img :src="imgPath + 'visual_ci.png'" />
                    <button type="button" class="default">
                        무료 컨설팅 상담신청<i></i>
                    </button>
                </div>
            </div>
        </section>

        <!-- 세미나 -->
        <section id="seminar" class="section hidden">
            <div class="inner">
                <h4>세미나</h4>
                <div class="contents">
                    <div class="list">
                        <ul>
                            <li>
                                <p class="flag"></p>
                                <p class="tit" data-text="title" data-clamp="1"></p>
                                <p class="local" data-text="/eachSeminar/content" data-clamp="1"></p>
                                <p class="date"></p>
                                <p class="address" data-text="/eachSeminar/location" data-clamp="2"></p>
                                <p class="desc" data-html="shortContent" data-clamp="2"></p>
                                <a class="link">세미나 참가신청</a>
                            </li>
                        </ul>
                    </div>
                    <div class="paging"></div>
                </div>
            </div>
            <i class="bg">
                <div class="contents">
                    <p class="seminar_tit"></p>
                </div>
            </i>
        </section>
        <!-- / 세미나 -->

        <!-- 실시간 상담현황 -->
        <section id="realTime"></section>
        <!-- / 실시간 상담현황 -->

        <!-- 상담신청 -->
        <section id="consulting" class="section"></section>
        <!-- / 상담신청 -->

        <!-- 상담 절차-->
        <section id="process" class="section">
            <div class="inner">
                <h4 class="tit_section"><strong>컨설팅 PROCESS</strong></h4>
                <div class="contents">
                    <p class="desc_section">
                        중소기업의 어려움을 함께 극복해나가고 싶습니다.<br />
                        전문가들과 상담부터 사후책임까지 매경경영지원본부가 함께하겠습니다.
                    </p>
                    <ul>
                        <li>
                            <img :src="imgPath + 'process_img01.png'" />
                            <p class="tit">1. 컨설팅 신청</p>
                        </li>
                        <li>
                            <img :src="imgPath + 'process_img02.png'" />
                            <p class="tit">2. 전문가 매칭</p>
                        </li>
                        <li>
                            <img :src="imgPath + 'process_img03.png'" />
                            <p class="tit">3. 사안 확인/ 기업 분석</p>
                        </li>
                        <li>
                            <img :src="imgPath + 'process_img04.png'" />
                            <p class="tit">4. 1:1 세부 미팅</p>
                        </li>
                        <li>
                            <img :src="imgPath + 'process_img05.png'" />
                            <p class="tit">5. 솔루션 도출 및 검토</p>
                        </li>
                        <li>
                            <img :src="imgPath + 'process_img06.png'" />
                            <p class="tit">6. 솔루션 실행</p>
                        </li>
                        <li>
                            <img :src="imgPath + 'process_img07.png'" />
                            <p class="tit">7. 지속 점검</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- / 상담 절차-->

        <!-- 언론보도 -->
        <section id="media" class="section">
            <div class="inner">
                <h4><strong>매경경영지원본부 언론보도</strong></h4>
                <div class="contents">
                    <ul>
                        <li>
                            <em class="cate"></em>
                            <strong data-text="title" data-clamp="2"></strong>
                            <span></span>
                            <i class="thumb"></i>
                        </li>
                    </ul>
                </div>
                <div class="btn_area">
                    <a href="/media/news" class="link_more">언론보도 전체 보기</a>
                </div>
            </div>
        </section>
        <!-- /언론보도 -->

        <section id="network" class="section">
            <div class="inner">
                <h4>
                    <strong>전문가 네트워크</strong>
                    <!-- <span><i>7</i><i>4</i></span> -->
                </h4>
                <div class="contents">
                    <ul class="net_list">
                        <li data-attr-type="type">
                            <em>
                                <b data-html="type"></b>
                                <span data-attr-name="name" data-html="name"></span>
                            </em>
                        </li>
                    </ul>
                    <div class="mem_grp_wrap">
                        <div class="mem_grp">
                            <ul class="mem_list">
                                <li data-attr-type="type">
                                    <em>
                                        <span class="mem_role"></span>
                                        <span class="mem_name"></span>
                                    </em>
                                    <img src="" alt="">
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button type="button" class="mem_arrow mem_prev" data-json-path="/image/common/prev03.png">
                        이전
                    </button>
                    <button type="button" class="mem_arrow mem_next" data-json-path="/image/common/next03.png">
                        다음
                    </button>
                </div>
                <a class="link_more" href="/company/network">전문가 전체 보기</a>
            </div>
        </section>

        <section id="board" class="section">
            <div class="contents">
                <ul>
                    <li>
                        <canvas></canvas>
                        <strong data-text="title"></strong>
                        <p data-text="source"></p>
                        <em>
                            <b data-attr-count="count"></b>
                            <i data-text="unit"></i>
                        </em>
                        <span data-html="comment"></span>
                    </li>
                </ul>
                <button type="button" class="board_prev" data-json-path="/image/common/prev03.png">
                    이전
                </button>
                <button type="button" class="board_next" data-json-path="/image/common/next03.png">
                    다음
                </button>
            </div>
            <div class="inner">
                <div class="particle_circle" style="top: -560px; left: 130px"></div>
                <div class="particle_circle-border" style="top: -670px; left: 200px"></div>
            </div>
        </section>

         <!-- 전문가 영상 -->
         <section id="video" class="section">
            <div class="inner">
                <h4><strong>중소기업경영에 도움되는 전문가 영상</strong></h4>
                <div class="contents">
                    <div class="swiper-container">
                        <ul class="swiper-wrapper list_news">
                            <li class="swiper-slide item_news">
                                <button class="btn_news" type="button">
                                    <em></em>
                                    <div class="thumb">
                                        <i class="ico_play"></i>
                                        <img src="" alt="">
                                    </div>
                                    <p class="tit" data-text="title" data-clamp="2" data-line="2"></p>
                                    <div class="view_info">
                                        <p class="date" data-text="writeDate"></p>
                                        <p class="count" data-number="viewCnt"></p>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="btn_arrow swiper-button-prev" data-json-path="/image/common/prev03.png"></div>
                    <div class="btn_arrow swiper-button-next" data-json-path="/image/common/next03.png"></div>
                </div>
                <div class="btn_area">
                    <a href="/media/video" class="link_more">전체 보기</a>
                </div>
            </div>
        </section>
        <!-- / 전문가 영상 -->
    </main><!-- /main -->
</template>



<script>
    export default {
        data() {
            return {
                imgPath: "/img/main/",
            };
        },
    };
</script>



<style scoped lang="scss">
    @import "@/assets/scss/main/main.scss";
</style>