<template>
    <section id="consultingProcess" class="section">
        <div class="inner">
            <h3 class="tit_section"></h3>
            <div class="list_process list1">
                <img :src="imgPath+'type1_illust1.png'" alt="">
                <img :src="imgPath+'type1_illust2.png'" alt="">
                <img :src="imgPath+'type1_illust3.png'" alt="">
                <img :src="imgPath+'type1_illust4.png'" alt="">
                <img :src="imgPath+'type1_illust5.png'" alt="">
                <img :src="imgPath+'type1_illust6.png'" alt="">
                <img :src="imgPath+'type1_illust7.png'" alt="">
            </div>
            <div class="list_process list2">
                <img :src="imgPath+'type2_illust1.png'" alt="">
                <img :src="imgPath+'type2_illust2.png'" alt="">
                <img :src="imgPath+'type2_illust3.png'" alt="">
                <img :src="imgPath+'type2_illust4.png'" alt="">
                <img :src="imgPath+'type2_illust5.png'" alt="">
                <img :src="imgPath+'type2_illust6.png'" alt="">
                <img :src="imgPath+'type2_illust7.png'" alt="">
                <img :src="imgPath+'type2_illust8.png'" alt="">
            </div>
            <div class="list_process list3">
                <img :src="imgPath+'type3_illust1.png'" alt="">
                <img :src="imgPath+'type3_illust2.png'" alt="">
                <img :src="imgPath+'type3_illust3.png'" alt="">
                <img :src="imgPath+'type3_illust4.png'" alt="">
                <img :src="imgPath+'type3_illust5.png'" alt="">
                <img :src="imgPath+'type3_illust6.png'" alt="">
            </div>
            <div class="particle_circle" style="bottom:240px;left:-170px"></div>
            <div class="particle_circle-border" style="bottom:130px;left:-140px"></div>
            <div class="particle_circle-diagonal" style="top:50px;right:-55px;width:170px;height:170px"></div>
            <div class="particle_circle-border" style="top:200px;right:-60px"></div>
        </div>
    </section>
</template>



<script>
import $ from 'jquery'

export default {
    name: 'consultingProcess',
    data() {
        return {
            imgPath: '/img/common/contents/consultingProcess/'
        }
    },
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



            var $wrap = $('#consultingProcess');
            var $list = $wrap.find('.list_process');

            //title 변경
            var title = $wrap.attr('data-title');
            $wrap.find('.tit_section').text(title);

            //list 변경
            var idx = $wrap.attr('data-list-idx') - 1;
            $list.hide().eq(idx).show();



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>



<style lang="scss">
    $imgPath : "/img/common/contents/consultingProcess/";

    #consultingProcess {
        padding-bottom:90px;background-color:#f6f6f6;
        .tit_section {
            position:relative;margin-bottom:55px;padding:90px 0 16px;font-size:30px;font-weight:500;line-height:1em;text-align:center;
            &::before {
                content:"";position:absolute;bottom:0;left:50%;width:90px;height:4px;background-color:#ff6815;transform:translateX(-50%);
            }
        }
        .list_process {
            position:relative;
        }
    }

    #consultingProcess[data-list-idx="1"] {
        .list_process {
            height:540px;
            img {
                $i: 0;
                $value:
                    0 37, 5 345, 20 667, 250 880, 265 600, 317 310, 344 47;
                @each $top, $left in $value {
                    $i: $i + 1;
                    &:nth-child(#{$i}) {
                        position:absolute;top:#{$top}px;left:#{$left}px;opacity:0;transform:translateY(10%);transition:none;
                    }
                }
            }
        }
    }

    #consultingProcess[data-list-idx="2"] {
        .list_process {
            height:777px;
            img {
                $i: 0;
                $value:
                    0 36, 61 346, 20 667, 315 880, 350 600, 364 310, 350 45, 590 312;
                @each $top, $left in $value {
                    $i: $i + 1;
                    &:nth-child(#{$i}) {
                        position:absolute;top:#{$top}px;left:#{$left}px;opacity:0;transform:translateY(10%);transition:none;
                    }
                }
            }
        }
    }

    #consultingProcess[data-list-idx="3"] {
        .list_process {
            height:550px;
            img {
                $i: 0;
                $value:
                    0 36, 30 346, 15 667, 282 880, 308 600, 345 350;
                @each $top, $left in $value {
                    $i: $i + 1;
                    &:nth-child(#{$i}) {
                        position:absolute;top:#{$top}px;left:#{$left}px;opacity:0;transform:translateY(10%);transition:none;
                    }
                }
            }
        }
    }

    #consultingProcess {
        &.appear {
            .list_process {
                img {
                    @for $i from 1 through 10 {
                        &:nth-child(#{$i}) {
                            opacity:1;transform:translateY(0);transition:all 0.8s #{$i * 0.6}s ease-in-out;
                        }
                    }
                }
            }
        }
    }
</style>
