<template>
<!-- main -->
<main id="container">
    <!-- 바로가기 -->
    <nav id="nav_container">
        <div class="list_nav">
            <a class="link_nav" data-target="#visual"><i></i><span>Main</span></a>
            <a class="link_nav" data-target="#optimum"><i></i><span>최적의 기업지배구조</span></a>
            <a class="link_nav" data-target="#effect"><i></i><span>개선 이유 및 기대효과</span></a>
            <a class="link_nav" data-target="#consultingProcess"><i></i><span>지배구조개선 2주 프로그램</span></a>
            <a class="link_nav" data-target="#networkTab"><i></i><span>전문가 네트워크</span></a>
            <a class="link_nav" data-target="#consulting"><i></i><span>무료 상담신청</span></a>
            <a class="link_nav" data-target="#consultingField"><i></i><span>바로가기</span></a>
        </div>
    </nav>
    <!-- //바로가기 -->

    <!-- 메인 -->
    <section id="visual" data-type="a">
        <div class="inner">
            <h3 class="tit_section">지배구조개선</h3>
            <p class="desc_section">
                비상장기업 지배구조란 <mark>특정회사 및 그 관계회사에 대한 소유권과 경영권의 구조 및 자본구조 등을</mark><br>
                <mark>의미하는 경우가 대부분이며, 중소기업 오너 CEO 리스크의 상당 부분이 이 지배구조 문제와 연결</mark>되어 있습니다.<br>
                기업의 소유권 구조에 큰 변화가 발생하는 내용 등 <mark>경영권에 대한 내용들이 대표적인 지배구조와 관련된 오너 CEO 리스크들</mark>입니다.
            </p>
            <button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span></button>
            <div class="list_visual">
                <button class="btn_visual" data-target="#optimum" type="button">
                    <img class="ico" src="/img/consulting/restructure/visual_ico1.png" alt="">
                    <p class="tit">최적의 기업지배구조</p>
                    <p class="desc">최적의 기업지배구조가 되기 위해<br> 고려해야 할 점들을 알아봅니다.</p>
                </button>
                <button class="btn_visual" data-target="#effect" type="button">
                    <img class="ico" src="/img/consulting/restructure/visual_ico2.png" alt="">
                    <p class="tit">개선 이유 및 기대효과</p>
                    <p class="desc">지배구조개선 필요성과<br> 기대효과를 확인하세요.</p>
                </button>
                <button class="btn_visual" data-target="#consultingProcess" type="button">
                    <img class="ico" src="/img/consulting/restructure/visual_ico3.png" alt="">
                    <p class="tit">지배구조개선<br>2주 프로그램</p>
                    <p class="desc">지배구조개선<br> 2주 프로그램 확인! </p>
                </button>
                <button class="btn_visual" data-target="#consulting" type="button">
                    <img class="ico" src="/img/consulting/restructure/visual_ico4.png" alt="">
                    <p class="tit">무료 상담신청</p>
                    <p class="desc">이제 고민은 그만!<br>전문가가 해결해드립니다. </p>
                </button>
            </div>
            <div class="particle_circle" style="bottom:15px;right:450px"></div>
            <div class="particle_circle-border" style="top:-130px;right:470px"></div>
        </div>
        <div class="particle_circle-double" style="bottom:-170px;left:80px;width:270px;height:270px"></div>
        <div class="particle_circle-double" style="top:10px;right:-120px;width:290px;height:290px"></div>
    </section>
    <!-- //메인 -->

    <!-- 기업지배구조 -->
    <section id="optimum" class="section">
        <div class="inner">
            <h3 class="tit_section">최적의 기업지배구조</h3>
            <div class="cons_info_area">
                <p class="tit_cons text-center">최적의 기업지배구조 </p>
                <ul class="list">
                    <li class="item">
                        <img src="/img/consulting/restructure/optimum_item1.png" alt="">
                        <p>자본구조</p>
                    </li>
                    <li class="item">
                        <img src="/img/consulting/restructure/optimum_item2.png" alt="">
                        <p>임원 및 회사의<br> 기관구성</p>
                    </li>
                    <li class="item">
                        <img src="/img/consulting/restructure/optimum_item3.png" alt="">
                        <p>주주구성</p>
                    </li>
                    <li class="item">
                        <img src="/img/consulting/restructure/optimum_item4.png" alt="">
                        <p>이익금회수방안</p>
                    </li>
                </ul>
                <div class="list-footer">
                    <img src="/img/consulting/restructure/optimum_footer.png" alt="">
                </div>
            </div>
            <div class="cons_info_area mb-40">
                <div class="box_tit">
                    이 과정에서 비상장사의 특성상 외부 이해관계자는 적으므로 소액주주 보호, 내부 감시 및 통제 등에 대한<br> 리스크 요인보다는 세금과 관련된 리스크 요인이 더 큰 비중을 차지하므로 절세측면이 중요하게 고려되어야 합니다.
                    <div class="tip">
                        <p class="tit_tip">TIPS</p>
                        <p class="desc_tip">가업승계를 위해서는 새롭게 시작하는 성장성 있는 사업을 자녀 명의의 법인으로 하는 것이 유리한 이유는 추후 가업승계 시<br> 부모님의 지분을 인수할 때 자녀명의의 법인을 활용하는 것이 절세나 자금조달/활용 측면에서 유리한 점이 있습니다. </p>
                    </div>
                </div>
            </div>
            <div class="cons_info_area">
                <div class="box_checkpoint">
                    <p class="tit">기업지배구조개선 처리 시 <strong><img class="ico" src="" data-json-path="/image/svg/check01.svg">체크 포인트</strong></p>
                    <div class="list_desc">
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">세금과 비용을 절감할 수 있느냐가 중요합니다.</p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">합법적인 절차와 규정을 준수해야 합니다.</p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">신설회사 분할에 대한 국세청 소명 요구 대응 전략에 대한 수립 여부를 확인해야 합니다.</p>
                    </div>
                    <img class="clip" src="/img/common/ico/clip.png" alt="">
                    <img class="illust" src="/img/consulting/restructure/optimum_checkpoint_illust.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- //기업지배구조 -->

    <!-- 기대효과 -->
    <section id="effect">
        <div class="inner">
            <h3 class="tit_section">개선 이유 및 기대효과</h3>
            <div class="cons_info_area">
                <p class="tit_cons">1. 지주회사를 통한 기업지배구조 개선이 필요한 이유</p>
                <ul class="list_box pt-10">
                    <li class="item_box">
                        <img class="illust" style="top:32px" src="/img/consulting/restructure/effect_illust1.png" alt="">
                        <p class="tit">가업승계를 위해 법인의<br> 지배구조개선이 필요</p>                        
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:18px" src="/img/consulting/restructure/effect_illust2.png" alt="">
                        <p class="tit">비상장주식 가치가 높아 <br> 가업승계가 어려운 이유</p>                        
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:43px" src="/img/consulting/restructure/effect_illust3.png" alt="">
                        <p class="tit">자회사를 취득해야 하는 경우</p>                        
                    </li>
                </ul>
            </div>
            <div class="cons_info_area mb-65">
                <p class="tit_cons">2. 기업지배구조개선을 위한 지주회사 기대효과</p>
                <ul class="list_box pt-10">
                    <li class="item_box">
                        <img class="illust" style="top:40px" src="/img/consulting/restructure/effect_illust4.png" alt="">
                        <p class="tit">대주주 지분율 증가로 인해<br> 기업의 지배력 강화 가능</p>                        
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:40px" src="/img/consulting/restructure/effect_illust5.png" alt="">
                        <p class="tit">경영권 방어 가능</p>                        
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:45px" src="/img/consulting/restructure/effect_illust6.png" alt="">
                        <p class="tit">세 부담을 줄인<br> 성공적인 가업승계 가능</p>                        
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- //기대효과 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="3" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="section"></section>
    <!-- //무료 컨설팅 상담신청 -->

    <!-- 컨설팅 분야 바로가기 -->
    <consultingField />
    <!-- //컨설팅 분야 바로가기 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){




        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/restructure.scss';
</style>