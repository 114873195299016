<template>
    <!-- main -->
    <main id="container">
        <!-- 메인비주얼 -->
        <section id="visual" class="section">
            <div class="inner">
                <h3 class="tit_section">
                    네트워크 전문가들이 여러분의 입장에서 함께합니다.
                </h3>
                <p class="desc_section">
                    매경경영지원본부의 자산관리 및 경영효율화 지원 네트워크 전문가 그룹의
                    원스톱 토탈 솔루션은<br>때로는 독립적으로, 때로는 다 같이 팀을
                    구성하여 최고의 솔루션을 구현합니다.
                </p>
            </div>
        </section>
        <!-- //메인비주얼 -->

        <!-- 전문가 네트워크 -->
        <networkTab></networkTab>
        <!-- //전문가 네트워크 -->

        <!-- 인물정보 -->
        <section id="networkList">
            <div class="inner">
                <p class="tit_section"><strong></strong></p>
                <div class="ready">
                    <div class="hourglass">
                        <div class="tit"><strong>프로필 준비중</strong> 입니다.</div>
                        <img class="ico" :src="imgPath + 'networkList_hourglass.png'" alt="">
                        <i class="ico_shadow"></i>
                    </div>
                    <div class="particle_circle" style="bottom: 20px; left: 150px"></div>
                    <div class="particle_circle" style="top: 70px; right: 165px"></div>
                    <div class="particle_circle-border" style="bottom: 60px; left: 95px"></div>
                </div>
                <ul class="list_member">
                    <li class="item_member">
                        <div class="inner_item">
                            <p class="tit" data-type="">
                                <span class="role"></span>
                                <span class="name"></span>
                            </p>
                            <button class="btn_more" type="button">
                                <img class="ico" src="" data-json-path="/image/svg/plus03.svg" alt="">
                            </button>
                            <div class="thumb">
                                <img src="" alt="">
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <!-- //인물정보 -->
    </main><!-- /main -->
    <!-- //container -->
</template>



<script>
    import $ from "jquery";

    export default {
        data() {
            return {
                imgPath: "/img/company/network/",
            };
        },
        mounted() {
            $(function () {
                // DOCUMENT READY...
                /*
                  ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                  */
                (function () {})();
                /*
                       ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
                       */
            }); // DOCUMENT READY...
        },
    };
</script>



<style scoped lang="scss">
    @import "@/assets/scss/company/network.scss";
</style>