<template>
  <section id="networkTab">
    <div class="inner">
      <h3 class="tit_section"></h3>
      <div class="tab">
        <button class="btn_tab" data-type="법무" data-group="gaius" type="button" ></button>
        <button class="btn_tab" data-type="노무" data-group="ikseon" type="button" ></button>
        <button class="btn_tab" data-type="노무" data-group="seohyun" type="button" ></button>
        <button class="btn_tab" data-type="M" data-group="manda" type="button" ></button>
        <button class="btn_tab" data-type="기업금융" data-group="ipo" type="button" ></button>
        <button class="btn_tab" data-type="부동산" data-group="estate" type="button" ></button>
        <button class="btn_tab" data-type="경정청구" data-group="dpbs" type="button" ></button>
      </div>
    </div>
  </section>
</template>



<script>
export default {
  name: "networkTab",
};
</script>



<style lang="scss">
$imgPath: "/img/common/contents/networkTab/";

#networkTab {
  padding: 70px 0 50px;
  .tit_section {
    position: relative; padding-bottom: 0; font-size: 36px; font-weight: bold; line-height: 1em; text-align: center;
    &::after {
      content: "전문가 네트워크";
    }
    /* &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 90px;
      height: 4px;
      background-color: #ff6815;
      transform: translateX(-50%);
    } */
  }
  .tab {
    position: relative;
    height: 350px;
    margin: 90px 0 30px;
  }

  .btn_tab {
    $i: 0;
    $value: 
      "가이우스\A법률사무소" 141px 79px -16px left orange ,
      "노무법인\A익선" 393px 98px 7px left orange ,
      "노무법인\A서현" 257px 94px 5px left orange ,
      "한국\AM&A거래소" 525px 114px 7px right blue ,
      "IPO·투자유치·\AM&A자문" 690px 69px -27px right blue ,
      "부동산\A중개" 781px 93px 6px right blue ,
      "DPBS" 888px 132px 34px left blue ;
    @each $content, $left1, $width, $left2, $tAlign, $tColor in $value {
      $i: $i + 1;
      &:nth-child(#{$i}) {
        position: absolute;
        left: #{$left1};
        width: #{$width};
        height: 281px;
        transform: scale(1.08);
        background-image: url(#{$imgPath}illust#{$i+1}.png);
        background-size: contain;
        transition: all 0.2s ease-in-out;
        &::after {
          content: "#{$content}";
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: -70px;
          left: #{$left2};
          width: 94px;
          height: 60px;
          padding-top:10px;
          font-size: 14px;
          color: #505056;
          line-height: 1.3em;
          letter-spacing: -0.03emp;
          white-space: pre-line;
          background-image: url(#{$imgPath}tooltip_#{$tAlign}.png);
          background-size: contain;
          transition: all 0.2s ease-in-out;
        }
        &.is-active,
        &:hover {
          background-image: url(#{$imgPath}illust#{$i+1}_on.png);
          &::after {
            color: #fff;
            background-image: url(#{$imgPath}tooltip_#{$tAlign}_on_#{$tColor}.png);
          }
        }
      }
    }
  }

  .bar {
    position: absolute;
    top: 0;
    height: 40px;
    padding-bottom: 14px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4px;
    }
    &.fst {
      left: 0;
      width: 700px;
      color: #ff6815;
      &::after {
        background-color: #ff6815;
      }
    }
    &.lst {
      right: 0;
      width: 425px;
      color: #2b89e3;
      &::after {
        background-color: #2b89e3;
      }
    }
  }

  &.is-event-none {
    pointer-events: none;
    @for $i from 1 through 20 {
      .btn_tab:nth-child(#{$i}) {
        background-image: url(#{$imgPath}illust#{$i+1}_on.png);
        opacity: 0;
        transform: translateY(5%) scale(1.08);
        transition: none;
      }
      &.appear {
        .btn_tab:nth-child(#{$i}) {
          opacity: 1;
          transform: translateY(0) scale(1.08);
          transition: all 0.6s #{$i * 0.1}s ease-in-out;
        }
      }
    }

    // .btn_tab {
    //     &:nth-child(1) {display: block;}
    // }
  }
}
</style>