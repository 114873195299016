<template>
    <section id="consultingProcess_b" class="section">
        <div class="inner">
            <h3 class="tit_section"></h3>
            <div class="wrap_process">
                <ul class="list_process">
                    <li class="item_process">
                        <p class="step">STEP<br>1</p>
                        <img :src="imgPath+'type1_illust1.png'" alt="">
                        <p class="tit">상담신청</p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>2</p>
                        <img :src="imgPath+'type1_illust2.png'" alt="">
                        <p class="tit">전문가 매칭 </p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>3</p>
                        <img :src="imgPath+'type1_illust3.png'" alt="">
                        <p class="tit">기업 검진</p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>4</p>
                        <img :src="imgPath+'type1_illust4.png'" alt="">
                        <p class="tit">세부상담 미팅</p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>5</p>
                        <img :src="imgPath+'type1_illust5.png'" alt="">
                        <p class="tit">분석 및 브리핑<br> (솔루션 제안) </p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>6</p>
                        <img :src="imgPath+'type1_illust6.png'" alt="">
                        <p class="tit">솔루션 실행</p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>7</p>
                        <img :src="imgPath+'type1_illust7.png'" alt="">
                        <p class="tit">지속 점검</p>
                    </li>
                </ul>
                <img class="bg" :src="imgPath+'type1_bg.png'" alt="">
            </div>
            <div class="wrap_process">
                <ul class="list_process">
                    <li class="item_process">
                        <p class="step">담당자<br>매칭</p>
                        <img :src="imgPath+'type1_illust2.png'" alt="">
                        <p class="tit">담당자 매칭</p>
                        <p class="sub_title">
                            <span>·</span>
                            <span>·</span>
                            지역별, 주제별 이슈를<br>
                            파악 후 최적의 전문가 선정
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">DAY<br>1</p>
                        <img :src="imgPath+'type1_illust3.png'" alt="">
                        <p class="tit">1차 미팅</p>
                        <p class="sub_title">
                            <span>·</span>
                            <span>·</span>
                            지배구조개선에 관한 CEO <br>
                            리스크 및 법인현황 파악
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">DAY<br>5</p>
                        <img :src="imgPath+'type1_illust4.png'" alt="">
                        <p class="tit">솔루션 도출 후<br> 2차 미팅</p>
                        <p class="sub_title">
                            <span>·</span>
                            <span>·</span>
                            제안서 제출, 검토
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">DAY<br>10</p>
                        <img :src="imgPath+'type1_illust5.png'" alt="">
                        <p class="tit">협약서 및 <br> 정보준수서약서 작성</p>
                    </li>
                    <li class="item_process">
                        <p class="step">DAY<br>14</p>
                        <img :src="imgPath+'type1_illust6.png'" alt="">
                        <p class="tit">지배구조개선<br>솔루션 실행</p>
                    </li>
                    <li class="item_process">
                        <p class="step">DAY<br>14~</p>
                        <img :src="imgPath+'type1_illust7.png'" alt="">
                        <p class="tit">솔루션 실행 후<br>사후관리</p>
                    </li>
                </ul>
                <img class="bg" :src="imgPath+'type2_bg.png'" alt="">
            </div>
            <div class="wrap_process">
                <ul class="list_process">
                    <li class="item_process">
                        <p class="step">STEP<br>1</p>
                        <img :src="imgPath+'type1_illust11.png'" alt="">
                        <p class="tit">
                            주총소집 위한 이사회<br>
                            (이사회 의사록 작성) 
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>2</p>
                        <img :src="imgPath+'type1_illust2.png'" alt="">
                        <p class="tit">
                            주주에게 통지<br>
                            (주총소집 통지서)
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>3</p>
                        <img :src="imgPath+'type1_illust3.png'" alt="">
                        <p class="tit">
                            주주총회<br>
                            (임시주주총회 의사록 작성)
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>4</p>
                        <img :src="imgPath+'type1_illust10.png'" alt="">
                        <p class="tit">
                            이사회<br>
                            (이사회 의사록 작성)
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>5</p>
                        <img :src="imgPath+'type1_illust9.png'" alt="">
                        <p class="tit">
                            주주에게 통지<br>
                            (통지서)
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>6</p>
                        <img :src="imgPath+'type1_illust12.png'" alt="">
                        <p class="tit">
                            양도신청<br>
                            (양도신청서 작성)
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>7</p>
                        <img :src="imgPath+'type1_illust6.png'" alt="">
                        <p class="tit">
                            주식매입<br>
                            (매매계약서 작성_ <br>
                            1개월 이내)
                        </p>
                    </li>
                    <li class="item_process">
                        <p class="step">STEP<br>8</p>
                        <img :src="imgPath+'type1_illust8.png'" alt="">
                        <p class="tit">
                            증권거래세, <br>
                            양도소득세 신고 <br>
                            (증권거래세, 양도소득세<br>
                            신고서 및 부속서류 작성)
                        </p>
                    </li>
                </ul>
                <img class="bg" :src="imgPath+'type3_bg.png'" alt="">
            </div>
        </div>
    </section>
</template>



<script>
import $ from 'jquery'

export default {
    name: 'consultingProcess_b',
    data() {
        return {
            imgPath: '/img/common/contents/consultingProcess_b/'
        }
    },
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



            var $wrap = $('#consultingProcess_b');
            var $list = $wrap.find('.wrap_process');

            //title 변경
            var title = $wrap.attr('data-title');
            $wrap.find('.tit_section').text(title);

            //list 변경
            var idx = $wrap.attr('data-list-idx') - 1;
            $list.hide().eq(idx).show();



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>



<style lang="scss">
    $imgPath : "/img/common/contents/consultingProcess_b/";

    #consultingProcess_b {
        padding: 70px 0 50px; background-image:url(#{$imgPath}bg.jpg);background-size:cover;
        .tit_section {
            position:relative;padding-bottom: 0;font-size:36px;font-weight:bold;line-height:1em;text-align:center;
        }
        .wrap_process {
            position:absolute;top:0;bottom:0;left:0;right:0; pointer-events: none;
            .item_process {
                position:absolute;z-index:1;
                .step {
                    display:flex;justify-content:center;align-items:center;position:absolute;left:50%;width:72px;height:72px;padding-top:5px;border-radius:100%;font-size:16px;color:#fff;line-height:1.3em;letter-spacing:0;text-align:center;background-color:#666;transform:translateX(-50%);
                    &::before {
                        content:"";position:absolute;bottom:-5px;left:50%;width:0;height:0;border-top:6px solid #666;border-right:3px solid transparent;border-left:3px solid transparent;transform:translateX(-50%);
                    }
                }
                .tit {
                    position:absolute;bottom:-65px;left:50%;height:45px;font-size:16px;font-weight:bold;color:#505056;text-align:center;white-space:nowrap;line-height: 1.3; transform:translateX(-50%);
                }
                .sub_title{
                    position: absolute; bottom:-115px; left:50%; font-size:16px; text-align: center;color:#505056;white-space:nowrap; transform:translateX(-50%);
                    span{display: block; line-height: 0.8;}
                }
            }
            .bg {
                position:absolute;
            }
        }
    }

    #consultingProcess_b[data-list-idx="1"] {
        height:780px;
        .item_process {
            $i: 0;
            $value:
                63    6      -80   #ff9215,
                110   209    -80   #fa8800,
                159   338    -87   #fa8800,
                200   525    -113  #fc7814,
                253   683    -65   #fc7915,
                302   878    -85   #ff680d,
                351   1025   -83   #ff6815;
            @each $bottom, $left, $top2, $bgColor in $value {
                $i: $i + 1;
                &:nth-child(#{$i}) {
                    bottom:#{$bottom}px;left:#{$left}px;opacity:0;transform:translateY(10%);transition:none;
                    .step {
                        top:#{$top2}px;background-color:#{$bgColor};
                        &::before {
                            border-top-color:#{$bgColor};
                        }
                    }
                }
            }
        }
        .bg {
            bottom:43px;left:-5px;
        }
    }
    #consultingProcess_b[data-list-idx="2"] {
        height: 820px; 
        .item_process {
            $i: 0;
            $value:
                152   120    -80   #ff9215,
                200   249    -85   #fa8800,
                241   439    -110  #fa8800,
                297   587    -60   #fc7814,
                342   780    -80   #fc7915,
                390   932    -85   #ff680d;
            @each $bottom, $left, $top2, $bgColor in $value {
                $i: $i + 1;
                &:nth-child(#{$i}) {
                    bottom:#{$bottom}px;left:#{$left}px;opacity:0;transform:translateY(10%);transition:none;
                    .step {
                        top:#{$top2}px;background-color:#{$bgColor};
                        &::before {
                            border-top-color:#{$bgColor};
                        }
                    }
                }
            }
        }
        .bg {
            bottom:130px;left:73px;
        }
    }
    #consultingProcess_b[data-list-idx="3"] {
        height: 780px; 
        .item_process {
            $i: 0;
            $value:
                67    33     -60   #ff9215 -45,
                125   224    -87   #fa8800 -65,
                167   335    -95   #fa8800 -60,
                207   487    -65   #fc7814 -60,
                245   640    -98   #fc7915 -60,
                288   796    -91   #fc7915 -60,
                333   949    -93   #ff6815 -65,
                363   1092   -100  #ff6815 -55;
            @each $bottom, $left, $top2, $bgColor, $titBtm in $value {
                $i: $i + 1;
                &:nth-child(#{$i}) {
                    bottom:#{$bottom}px;left:#{$left}px;opacity:0;transform:translateY(10%);transition:none;
                    .step {
                        top:#{$top2}px;background-color:#{$bgColor};
                        &::before {
                            border-top-color:#{$bgColor};
                        }
                    }
                    .tit{bottom:#{$titBtm}px;}
                }
            }
        }
        .bg {
            bottom:64px;left:44px;
        }
    }

    #consultingProcess_b {
        &.appear {            
            .item_process {                                
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        opacity:1;transform:translateY(0);transition:all 0.6s #{$i * 0.2}s ease-in-out;
                    }
                }                
            }
        }
    }
</style>
