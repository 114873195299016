<template>
<!-- main -->
<main id="container">
    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <h3 class="tit_section">매경경영지원본부는 법인컨설팅 분야에서<br><span class="color-ffd200">매</span>번 뛰어난 <span class="color-ffd200">경</span>제력을 입증하고 있습니다.</h3>
            <p class="desc_section">매경경영지원본부는 고객사와 함께 분명하고 실질적인 액션플랜을 수립하고 지속가능한 성장발전을 계획을 실행 해왔습니다.<br>중소기업 CEO 분들이 계획하는 100년 기업의 큰 그림을 위해 보다 전문적이고 실질적인 가이드를 위해 한 층 노력할 것입니다.</p>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 지원안내 -->
    <section id="support">
        <div class="inner">
            <ul class="list_support">
                <li class="item_support">
                    <div class="box_txt">
                        <p class="tit">구체적인 실행지원과 사후 책임관리</p>
                        <p class="desc">중소기업이 안고있는 공통적인 문제의 해결을 위한 솔루션은 다양할 수 있습니다.<br> 그러나, 정말 중요한 것은 개별 기업에 꼭 맞아야 하며 실행 가능한 대안이어야<br>합니다.<br> 그리고, 솔루션 적용 이후의 사후관리까지 염두에 두어야 합니다.<br> 지원본부는 고민의 시작부터 사후관리까지 전문가로 구성된 One Team이<br>One-Stop-Service를 책임집니다.</p>
                    </div>
                    <div class="box_thumb">
                        <img src="/img/company/introduce/support_thumb1.jpg" alt="">
                        <i class="particle_circle-border" style="top:0;left:0"></i>
                        <i class="particle_circle-diagonal" style="bottom:-53px;left:-38px"></i> 
                    </div>
                </li>
                <li class="item_support">
                    <div class="box_txt">
                        <p class="tit">경영권 강화를 위한 기업구조의 기틀 마련</p>
                        <p class="desc">과거 제도의 틀에 막혀서 또는 차일피일 시일을 늦추다가 정작 중요한 경영권이 훼손될<br>수 있습니다.<br>안정된 경영권이 보장 되어야 미래 계획과 성장발전의 의미가 존중됩니다.<br> 지원본부는 변화되는 법과 제도에 대한 명쾌한 이해를 바탕으로 고객사의 상황에 맞는<br>기업구조 마련을 위해 도움을 드리고 있습니다.</p>
                    </div>
                    <div class="box_thumb">
                        <img src="/img/company/introduce/support_thumb2.jpg" alt="">
                        <i class="particle_circle" style="top:30px;left:19px"></i>
                    </div>
                </li>
                <li class="item_support">
                    <div class="box_txt">
                        <p class="tit">기업 오너에 대한 현실적인 성과 보상</p>
                        <p class="desc">단발성의 임기응변방식이 아닌 체계적이고 제도에 의한 보상과 회수 프로그램의<br>도입이 중요합니다.<br> 지원본부는 급여, 상여, 배당 그리고 퇴직 프로그램 등 기간과 방법을 달리하는 <br> 현실적인 프로그램을 보유하고 있어 실질적인 도움을 드리고 있습니다. </p>
                    </div>
                    <div class="box_thumb">
                        <img src="/img/company/introduce/support_thumb3.jpg" alt="">
                        <i class="particle_circle-border" style="top:96px;right:-57px"></i>
                        <i class="particle_circle-double" style="bottom:20px;left:0"></i>
                    </div>
                </li>
                <li class="item_support">
                   <div class="box_txt">
                        <p class="tit">불필요한 비용구조와 복잡성을 줄여 성과를 향상</p>
                        <p class="desc">회사가 조직적인 기반을 잡기 이전에 진행되었던 체계적이지 못했던 의사결정으로<br> 불필요하게 지출되는 부분들은 현재와 향후의 계획을 기반으로 재정비하여야 합니다.<br> 지원본부는 수 많은 기업사례를 기반으로 업종별, 규모별로 최적의 대안을 제시합니다. </p>
                   </div>
                    <div class="box_thumb">
                        <img src="/img/company/introduce/support_thumb4.jpg" alt="">
                    </div>
                </li>
                <li class="item_support">
                    <div class="box_txt">
                        <p class="tit">계속 기업을 위한 승계구도의 완성</p>
                        <p class="desc">100년 기업을 위한 계획을 위하여 제도적 조직적 그리고 인사적인 점검이 선행이<br>되어야 합니다.<br> 무엇보다 중요한 것은 기업 오너의 장기계획과 철학이어서 그것에 대한 사전준비가<br>더 필요합니다.<br> 지원본부는 기업의 최종 목표인 계속기업을 위한 고민을 함께하고 있습니다. </p>
                    </div>
                    <div class="box_thumb">
                        <img src="/img/company/introduce/support_thumb5.jpg" alt="">
                        <i class="particle_circle-double" style="bottom:-87px;right:-187px"></i>
                    </div>
                </li>
            </ul>
        </div>
        <i class="particle_circle-double" style="width:280px;height:280px;top:1137px;right:-128px"></i>
    </section>
    <!-- //지원안내 -->

    <!-- 오시는길 -->
    <section id="map" class="section">
        <div class="inner">
            <h3 class="tit_section">오시는길</h3>
            <p class="desc_section">주소 : <span data-peoplelife="address"></span> <span data-peoplelife="addressDetail"></span><br>전화번호 : 1800-9440   /   FAX : 02-6909-2130</p>            
            <div class="kakaomap"></div>
        </div>
    </section>
    <!-- //오시는길 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){




        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/company/introduce.scss';
</style>