<template>
    <main id="container">
        <!-- 신청자 정보 -->
        <section id="info">
            <div class="inner">
                <h3 class="tit_section"></h3>
                <p class="desc_section"></p>
                <ul class="list_info consult">
                    <li class="item_info"><strong class="type">상담분야 :</strong><span class="info_type"></span></li>
                    <li class="item_info"><strong class="type">지역 :</strong><span class="info_region"></span></li>
                    <li class="item_info"><strong class="type">컨설팅 안내 받을 연락처 :</strong><span class="info_phone"></span></li>
                    <li class="item_info"><strong class="type">신청자 성함 :</strong><span class="info_name"></span></li>
                    <li class="item_info"><strong class="type">회사명 :</strong><span class="info_corpName"></span></li>
                </ul>
                <ul class="list_info seminar">
                    <li class="item_info"><strong class="type">세미나 :</strong><span class="info_seminar"></span></li>
                    <li class="item_info"><strong class="type">회사명 :</strong><span class="info_corpName"></span></li>
                    <li class="item_info"><strong class="type">사업자등록번호 :</strong><span class="info_corpNumber"></span></li>
                    <li class="item_info"><strong class="type">참가자 이름 :</strong><span class="info_name"></span></li>
                    <li class="item_info"><strong class="type">휴대폰 번호 :</strong><span class="info_phone"></span></li>
                </ul>
                <button class="btn_back" type="button">이전 화면으로 이동</button>
            </div>
            <div class="bg">
                <em>(주최 : 주간동아)</em>
                <img class="medal" onclick="history.back()" src="/etc/img/import/medal.png" alt="">
            </div>
        </section>
        <!-- //신청자 정보 -->

        <!-- 컨설팅 과정 -->
        <section id="step">
            <div class="inner">
                <ol class="list_step">
                    <li class="item_step">
                        <div class="illust">
                            <p class="tit"><span class="num">1.</span>컨설팅 신청</p>
                            <img :src="imgPath+'step_illust1.png'" alt="">
                        </div>
                    </li>
                    <li class="item_step">
                        <div class="illust">
                            <p class="tit"><span class="num">2.</span>전문가 매칭</p>
                            <img :src="imgPath+'step_illust2.png'" alt="">
                        </div>                       
                    </li>
                    <li class="item_step">
                        <div class="illust">
                            <p class="tit"><span class="num">3.</span>사안 확인/ 기업 분석</p>
                            <img :src="imgPath+'step_illust3.png'" alt="">
                        </div>                       
                    </li>
                    <li class="item_step">
                        <div class="illust">
                            <p class="tit"><span class="num">4.</span>1:1세부 미팅</p>
                            <img :src="imgPath+'step_illust4.png'" alt="">
                        </div>                       
                    </li>
                    <li class="item_step">
                        <div class="illust">
                            <p class="tit"><span class="num">5.</span>솔루션 도출<br>및 검토</p>
                            <img :src="imgPath+'step_illust5.png'" alt="">
                        </div>                       
                    </li>
                    <li class="item_step">
                        <div class="illust">
                            <p class="tit"><span class="num">6.</span>솔루션 실행</p>
                            <img :src="imgPath+'step_illust6.png'" alt="">
                        </div>                       
                    </li>
                    <li class="item_step">
                        <div class="illust">
                            <p class="tit"><span class="num">7.</span>지속 점검</p>
                            <img :src="imgPath+'step_illust7.png'" alt="">
                        </div>                       
                    </li>
                </ol>
            </div>
        </section>
        <!-- //컨설팅 과정 -->
    </main><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/etc/complete/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/etc/complete.scss';
</style>