<template>
<!-- main -->
<main id="container">
	<!-- 바로가기 -->
	<nav id="nav_container">
		<div class="list_nav">
			<a class="link_nav" data-target="#visual"><i></i><span>Main</span></a>
			<a class="link_nav" data-target="#example"><i></i><span>컨설팅 사례</span></a>
			<a class="link_nav" data-target="#optimum"><i></i><span>최적의 기업지배구조</span></a>
			<a class="link_nav" data-target="#effect"><i></i><span>개선 이유 및 기대효과</span></a>
			<a class="link_nav" data-target="#consultingProcess_b"><i></i><span>지배구조개선 2주 프로그램</span></a>
			<a class="link_nav" data-target="#networkTab"><i></i><span>전문가 네트워크</span></a>
			<a class="link_nav" data-target="#consulting"><i></i><span>무료 상담신청</span></a>
			<a class="link_nav" data-target="#consultingField_b"><i></i><span>바로가기</span></a>
		</div>
	</nav>
	<!-- //바로가기 -->

	<!-- 메인 -->
	<section id="visual" data-type="b">
		<div class="inner">
			<h3 class="tit_section">지배구조개선</h3>
			<p class="desc_section">
				비상장기업 지배구조란 특정회사 및 그 관계회사에 대한 소유권과 경영권의 구조 및 자본구조 등을<br>
				의미하는 경우가 대부분이며, 중소기업 오너 CEO 리스크의 상당 부분이 이 지배구조 문제와 연결되어 있습니다.<br>
				기업의 소유권 구조에 큰 변화가 발생하는 내용 등 <mark>경영권에 대한 내용들이 대표적인 지배구조와 관련된 오너 CEO 리스크들</mark>입니다.
			</p>
			<button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span><img class="ico" src="" data-json-path="/image/svg/arr_right02.svg"></button>
			<div class="list_visual">
				<button class="btn_visual" data-target="#optimum" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img01.png" alt="">
					<p class="tit">최적의 기업지배구조</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#effect" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img02.png" alt="">
					<p class="tit">개선 이유 및 기대효과</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#consultingProcess_b" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img03.png" alt="">
					<p class="tit">지배구조개선<br>2주 프로그램</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#consulting" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img04.png" alt="">
					<p class="tit">무료 상담신청</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
			</div>
		</div>
	</section>
	<!-- //메인 -->

	<!-- 컨설팅 사례 -->
	<section id="example" class="section" data-type="b">
		<div class="inner">
			<h3 class="tit_section">지배구조개선 컨설팅 사례 </h3>
			<div class="list_talk">
				<div class="item_talk left">
					<p class="tit_talk color-ffffff" style="color:#fff;">의뢰인</p>
					<img class="profile" src="/img/consulting/common/example_talk_profile_client3.png" alt="">
					<div class="tooltip">
						<p class="tit type2">문제점</p>
						<p>안산에서 제조업을 영위하는 A사의 김모 대표는 사업 확장을 위해 투자를 해야 할 상황으로 당초 본인이 증자하고 일부 차입을 더해 기존의 A사 명의로 투자를 하려고 하는데 이 방법이 맞는지 의뢰를 하였습니다.</p>
					</div>
					<button class="videoLayer" data-src="https://www.youtube.com/embed/RlJijOS9kXY" data-title="지배구조 개선을 통한 중소기업 대표의 리스크 해결!" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/RlJijOS9kXY/maxresdefault.jpg" alt="스크린샷">
					</button>
				</div>
				<div class="item_talk right" style="min-height:440px; margin-top:0;">
					<div class="profile"  style="height:503px; top:-35px; right:20px;">
						<img src="/img/consulting/common/example_talk_profile_professional3.png" alt="">
						<span class="profile_name">컨설팅 전문가</span>
					</div>
					<div class="tooltip" style="margin-top: 20px;">
						<p class="tit">해결방법</p>
						<ol>
							<li>가업승계 고려 시 투자로 기업가치를 높이는 것은 자금부담과 세금부담이 증가됩니다.</li>
							<li>자녀를 대주주로 하는 신설법인을 설립하고 신설법인 명의로 하여 투자를 집행하는 것이 유용합니다.</li>
							<li>가업승계 고려 시 주주구성을 변화시키는 것보다 기업지배구조 개선에 더 효과적입니다.</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- //컨실팅 사례 -->

	<!-- 기업지배구조 -->
	<section id="optimum" class="section">
		<div class="inner">
			<h3 class="tit_section">최적의 기업지배구조</h3>
			<div class="cons_info_area-round">
				<p class="tit_cons">최적의 기업지배구조 </p>
				<div class="cons_info_area">
					<ul class="thumb_list">
						<li>
							<img class="thumb" :src="imgPath+'restructure_img01.png'" alt="">
							<p class="tit">자본구조</p>
						</li>
						<li>
							<img class="thumb" :src="imgPath+'restructure_img02.png'" alt="">
							<p class="tit">임원 및 회사의<br> 기관구성</p>
						</li>
						<li>
							<img class="thumb" :src="imgPath+'restructure_img03.png'" alt="">
							<p class="tit">주주구성</p>
						</li>
						<li>
							<img class="thumb" :src="imgPath+'restructure_img04.png'" alt="">
							<p class="tit">이익금회수방안</p>
						</li>
					</ul>
				</div>
			</div>
			<div class="list_submit-footer">
				<img :src="imgPath+'restructure_arrow.png'" alt="" class="orange_arrow">
			</div>
			<div class="cons_info_area-round tip_box">
				<div class="cons_info_area">
					<p>이 과정에서 비상장사의 특성상 외부 이해관계자는 적으므로 소액주주 보호, 내부 감시 및 통제 등에 대한<br> 리스크 요인보다는 세금과 관련된 리스크 요인이 더 큰 비중을 차지하므로 절세측면이 중요하게 고려되어야 합니다.</p>
					<div class="tip">
						<p class="tit_tip">TIP</p>
						<p class="desc_tip">가업승계를 위해서는 새롭게 시작하는 성장성 있는 사업을 자녀 명의의 법인으로 하는 것이 유리한 이유는 추후 가업승계 시<br> 부모님의 지분을 인수할 때 자녀명의의 법인을 활용하는 것이 절세나 자금조달/활용 측면에서 유리한 점이 있습니다. </p>
					</div>
				</div>
			</div>
			<div class="cons_info_area-round">
				<div class="box_checkpoint cons_info_area">
					<p class="tit"><i><img src="/img/consulting/common/cons_info_area_checkpoint.png" alt=""></i> 기업지배구조개선 처리 시 <strong> Check point</strong></p>
					<div class="list_desc">
						<p class="desc"><i>Check&nbsp;</i>세금과 비용을 절감할 수 있느냐가 중요합니다.</p>
						<p class="desc"><i>Check&nbsp;</i>합법적인 절차와 규정을 준수해야 합니다.</p>
						<p class="desc"><i>Check&nbsp;</i>신설회사 분할에 대한 국세청 소명 요구 대응 전략에 대한 수립 여부를 확인해야 합니다.</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- //기업지배구조 -->

	<!-- 기대효과 -->
	<section id="effect" class="section">
		<div class="inner">
			<h3 class="tit_section">개선 이유 및 기대효과</h3>
			<div class="cons_info_area-round border">
				<p class="tit_cons">1. 지주회사를 통한 기업지배구조 개선이 필요한 이유</p>
				<div class="cons_info_area">
					<ul class="thumb_list">
						<li>
							<img class="thumb" :src="imgPath+'restructure_img05.png'" alt="">
							<p class="tit">가업승계를 위해 법인의<br> 지배구조개선이 필요</p>                        
						</li>
						<li>
							<img class="thumb" :src="imgPath+'restructure_img06.png'" alt="">
							<p class="tit">비상장주식 가치가 높아 <br> 가업승계가 어려운 이유</p>                        
						</li>
						<li>
							<img class="thumb" :src="imgPath+'restructure_img07.png'" alt="">
							<p class="tit">자회사를 취득해야 하는<br> 경우</p>                        
						</li>
					</ul>
				</div>
			</div>
			<div class="cons_info_area-round border">
				<p class="tit_cons">2. 기업지배구조개선을 위한 지주회사 기대효과</p>
				<div class="cons_info_area">
					<ul class="thumb_list">
						<li>
							<img class="thumb" :src="imgPath+'restructure_img08.png'" alt="">
							<p class="tit">대주주 지분율 증가로 인해<br> 기업의 지배력 강화 가능</p>                        
						</li>
						<li>
							<img class="thumb" :src="imgPath+'restructure_img09.png'" alt="">
							<p class="tit">경영권 방어 가능</p>                        
						</li>
						<li>
							<img class="thumb" :src="imgPath+'restructure_img10.png'" alt="">
							<p class="tit">세 부담을 줄인<br> 성공적인 가업승계 가능</p>                        
						</li>
					</ul>
				</div>
			</div>
			<div class="cons_info_area-round border">
				<p class="tit_cons">3. 주식회사의 지분율에 따른 주주권리(상법상 비상장회사 기준)</p>
				<em class="desc_cons">※ 주식회사의 총발행주식에 대하여 특정주주가 소유하고 있는 주식의 비율을 말하며 지주비율이라고도 한다.</em>
				<div class="cons_info_area cons_info_area">
					<ul>
						<li>
							<div class="thumb">
								<img src="/img/consulting/restructure_b/effect_img_1_1.png" alt="">
							</div>
							<p>
								<strong>1주</strong>
								<span>의결권, 주총하자에 대한 제소권</span>
							</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/restructure_b/effect_img_1_2.png" alt="">
							</div>
							<p>
								<strong>주식1%</strong>
								<span>대표이사 소송권</span>
							</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/restructure_b/effect_img_1_3.png" alt="">
							</div>
							<p>
								<strong>주식3%</strong>
								<span>지배주주의 권한 남용 견제,<br>위법행위 감시 및 통제</span>
							</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/restructure_b/effect_img_1_4.png" alt="">
							</div>
							<p>
								<strong>주식 25%</strong>
								<span>단독 출석시 보통결의사항 통과<br>(이사선임, 이익배당, 재무제표 승인)</span>
							</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/restructure_b/effect_img_1_5.png" alt="">
							</div>
							<p>
								<strong>주식 33.4%</strong>
								<span>단독 출석시 특별결의사항 통과<br>(정관변경, 회사의 합병, 이사의 해임 등)</span>
							</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/restructure_b/effect_img_1_6.png" alt="">
							</div>
							<p>
								<strong>주식 50% + 1주</strong>
								<span>보통결의사항 통과<br>(표결로 이길 수 있는 지분율)</span>
							</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/restructure_b/effect_img_1_7.png" alt="">
							</div>
							<p>
								<strong>주식 66.7%</strong>
								<span>특별결의사항 통과<br>(표결로 이길 수 있는 지분율)</span>
							</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/restructure_b/effect_img_1_8.png" alt="">
							</div>
							<p>
								<strong>주식100%</strong>
								<span>1인 주주</span>
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div class="cons_info_area-round border">
				<p class="tit_cons">4. 비상장주식가치 평가</p>
				<em class="desc_cons">비상장법인의 주식가치 평가방법은 ‘상속세 및 증여세법 시행령 제54조(비상장주식의 평가)’에 명시된 ‘순손익가치’와 ‘순자산가치’의 가중 평균에 의해 평가</em>
				<div class="img_area cons_info_area">
					<img src="/img/consulting/restructure_b/effect_img_2.png" alt="">
				</div>
			</div>
			<div class="cons_info_area-round border">
				<p class="tit_cons">5. 비상장주식가치 평가 활용 예시 : 임원(CEO)</p>
				<div class="img_area cons_info_area">
					<img src="/img/consulting/restructure_b/effect_img_3.png" alt="">
				</div>
			</div>
		</div>
	</section>
	<!-- //기대효과 -->

	<!-- 컨설팅 프로세스 -->
	<consultingProcess_b data-list-idx="2" data-title="지배구조개선 2주 프로그램" />
	<!-- //컨설팅 프로세스 -->

	<!-- 전문가 네트워크 -->
	<networkTab class="is-event-none" />
	<!-- //전문가 네트워크 -->

	<!-- 무료 컨설팅 상담신청 -->
	<section id="consulting" class="section"></section>
	<!-- //무료 컨설팅 상담신청 -->

	<!-- 컨설팅 분야 바로가기 -->
	<consultingField_b />
	<!-- //컨설팅 분야 바로가기 -->
</main><!-- /main -->
<!-- //container -->
</template>


<script>
import $ from 'jquery'

export default {
	mounted() {
		$(function(){ // DOCUMENT READY...
		/*
		■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
		*/(function(){




		})();/*
		■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
		*/});// DOCUMENT READY...
	},
	data() {
		return {
			imgPath: '/img/consulting/restructure_b/'
		}
	}
}
</script>



<style scoped lang="scss">
	@import '@/assets/scss/consulting/restructure_b.scss';
</style>