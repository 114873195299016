<template>
<!-- main -->
<main id="container">
	<!-- 바로가기 -->
	<nav id="nav_container">
		<div class="list_nav">
			<a class="link_nav" data-target="#visual"><i></i><span>Main</span></a>
			<a class="link_nav" data-target="#example"><i></i><span>컨설팅 사례</span></a>
			<a class="link_nav" data-target="#target"><i></i><span>법인전환 검토 대상 기업</span></a>
			<a class="link_nav" data-target="#business"><i></i><span>개인사업자 VS 법인사업자</span></a>
			<a class="link_nav" data-target="#consultingProcess_b"><i></i><span>컨설팅 프로세스</span></a>
			<a class="link_nav" data-target="#networkTab"><i></i><span>전문가 네트워크</span></a>
			<a class="link_nav" data-target="#consulting"><i></i><span>무료 상담신청</span></a>
			<a class="link_nav" data-target="#consultingField_b"><i></i><span>바로가기</span></a>
		</div>
	</nav>
	<!-- //바로가기 -->

	<!-- 메인 -->
	<section id="visual" data-type="b">
		<div class="inner">
			<h3 class="tit_section">법인전환</h3>
			<p class="desc_section">
				개인사업자의 법인전환이란 사업리스크와 세 부담을 줄이기 위해<br>
				개인기업이 경영의 권리와 의무를 법인(法人)으로 변경하는 것을 말합니다.<br>
				정부는 <mark>개인의 고소득세율을 상대적으로 낮은 법인세로 전환하기 위하여 다양한 세제상의 지원</mark>을 하고있습니다.
			</p>
			<button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span><img class="ico" src="" data-json-path="/image/svg/arr_right02.svg"></button>
			<div class="list_visual">
				<button class="btn_visual" data-target="#example" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img01.png" alt="">
					<p class="tit">컨설팅 사례</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#target" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img02.png" alt="">
					<p class="tit">법인전환 검토 대상 기업</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#business" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img03.png" alt="">
					<p class="tit">개인사업자 VS 법인사업자</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#consultingProcess_b" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img04.png" alt="">
					<p class="tit">컨설팅 프로세스</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
				<button class="btn_visual" data-target="#consulting" type="button">
					<img class="thumb" src="/img/consulting/certify_b/visual_img05.png" alt="">
					<p class="tit">무료 상담신청</p>
					<img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
				</button>
			</div>
		</div>
	</section>
	<!-- //메인 -->

	<!-- 컨설팅 사례 -->
	<section id="example" class="section" data-type="b">
		<div class="inner">
			<h3 class="tit_section">컨설팅 사례 </h3>
			<div class="list_talk">
				<div class="item_talk left">
					<p class="tit_talk color-ffffff" style="color:#fff;">의뢰인</p>
					<img class="profile" src="/img/consulting/common/example_talk_profile_client1.png" alt="">
					<div class="tooltip">
						<p class="tit type2">컨설팅 문의</p>
						<p>강남구 소재의 중소형빌딩을 20년전에 취득 후 양도, 상속, 증여 등의 다각도의 절세방안을 검토하였으나, 현물출자방식의 임대법인전환이 가장 합리적이라고 판단하여 이에 따른 방법과 절차에 대해 알고 싶습니다.</p>
					</div>
					<button class="videoLayer" data-src="https://www.youtube.com/embed/rw7BtqATOMg" data-title="법인전환 방법 총정리! 나에게 맞는 법인전환을 찾자!" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/rw7BtqATOMg/maxresdefault.jpg" alt="스크린샷">
					</button>
				</div>
				<div class="item_talk right">
					<div class="profile">
						<img src="/img/consulting/collection_b/example_talk_profile_professional1.png" alt="">
						<span class="profile_name">컨설팅 전문가</span>
					</div>
					<div class="tooltip">
						<p class="tit">해결방법</p>
						<ol>
							<li>임대용 부동산의 토지 및 건물의 가치를 감정평가사의 감정절차를<br>통해 평가, 회계사의 감사 및 결산이라는 절차를 통하여<br>신설법인을 설립하였습니다.</li>
							<li>법원의 설립인가를 위한 법무사의 법적인 실무작업을 동시에<br>진행하였습니다.</li>
							<li>지원본부의 세무사, 감정평가사, 법무사와 팀을 이뤄 현물출자<br>법인전환을 통해 개정된 주식가치 평가 방법과 이월된<br>양도소득세가 주식 가치 평가에 영향을 주어 주식가치가 하락하고<br>절대적인 상속재산의 가치를 평가절하 함으로 상속세 절세효과를<br>기대하게 되었습니다.</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- //컨실팅 사례 -->

	<!-- 대상기업 -->
	<section id="target" class="section">
		<div class="inner">
			<h3 class="tit_section">법인전환 검토 대상기업</h3>
			<div class="cons_info_area-round">
				<p class="tit_cons">1. 개인사업자 법인전환 검토 대상기업</p>
				<ul class="list_box cons_info_area">
					<li class="item_box">
						<img class="illust" :src="imgPath+'conversion_img01.png'" alt="">
						<div class="desc_box">
							<p class="desc">성실신고 확인제도 대상 기업으로<br> 세금부담이 크고, 과세당국의<br> 중점관리 대상이 되는 기업</p>
						</div>
					</li>
					<li class="item_box">
						<img class="illust" :src="imgPath+'conversion_img02.png'" alt="">
						<div class="desc_box">
							<p class="desc">가업승계에 따라 상속세와<br> 증여세 부담이 큰 기업</p>
						</div>
					</li>
					<li class="item_box">
						<img class="illust" :src="imgPath+'conversion_img03.png'" alt="">
						<div class="desc_box">
							<p class="desc">기업 소유 부동산 가격이 급격하게<br> 상승했거나 예정이어서, <br>양도소득세 부담이 큰 기업</p>
						</div>
					</li>
					<li class="item_box">
						<img class="illust" :src="imgPath+'conversion_img04.png'" alt="">
						<div class="desc_box">
							<p class="desc">순이익과 사업소득 이외에 배당소득,<br> 임대소득 등 타 소득이 높아<br> 종합소득세 부담이 큰 기업</p>
						</div>
					</li>
					<li class="item_box">
						<img class="illust" :src="imgPath+'conversion_img05.png'" alt="">
						<div class="desc_box">
							<p class="desc">임대사업자로 가업승계를 하려고 하나<br> 가업상속공제 대상에서 제외되어 있는 기업</p>
						</div>
					</li>
					<li class="item_box">
						<img class="illust" :src="imgPath+'conversion_img06.png'" alt="">
						<div class="desc_box">
							<p class="desc">금융권 대출이나 정부기관 입찰 참여를<br> 위해 대외 신용도를 높이고자 하는 기업</p>
						</div>
					</li>
					<li class="item_box">
						<img class="illust" :src="imgPath+'conversion_img07.png'" alt="">
						<div class="desc_box">
							<p class="desc">정부 정책자금 지원과 기타 고용지원<br> 정책의 혜택을 받고자 하는 기업</p>
						</div>
					</li>
				</ul>
				<div class="table_box border cons_info_area-round cons_info_area">
					<p class="tit_table"><strong>성실신고확인대상 수입기준 금액 조정</strong></p>
					<table class="table table-gray">
						<colgroup>
							<col style="width: auto;">
							<col style="width: auto;">
							<col style="width: auto;">
							<col style="width: auto;">
						</colgroup>
						<thead>
							<tr>
								<th>구분</th>
								<th>농업, 도소매업</th>
								<th>제조업, 건설업 </th>
								<th>서비스업 </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>2017년</td>
								<td>20억원 이상</td>
								<td>10억원 이상</td>
								<td>5억원 이상</td>
							</tr>
							<tr>
								<td>2018년~2019년</td>
								<td>15억원 이상</td>
								<td>7억 5천원 이상</td>
								<td>5억원 이상</td>
							</tr>
							<tr>
								<td>2020년</td>
								<td>10억원 이상</td>
								<td>5억원 이상</td>
								<td>3억5천원 이상</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="cons_info_area-round">
				<p class="tit_cons">2. 개인사업자 법인전환 방법</p>
				<ul class="thumb_list cons_info_area">
					<li>
						<img class="thumb" :src="imgPath+'conversion_img08.png'" alt="">
						<p class="tit">일반사업<br> 양.수도에 의한 방법</p>
					</li>
					<li>
						<img class="thumb" :src="imgPath+'conversion_img09.png'" alt="">
						<p class="tit">현물출자에<br> 의한 방법</p>
					</li>
					<li>
						<img class="thumb" :src="imgPath+'conversion_img10.png'" alt="">
						<p class="tit">포괄사업 양.수도에<br> 의한 방법</p>
					</li>
					<li>
						<img class="thumb" :src="imgPath+'conversion_img11.png'" alt="">
						<p class="tit">중소기업 간<br> 통합에 의한 방법</p>
					</li>
				</ul>
			</div>
			<div class="cons_info_area-round">
				<p class="tit_cons">3.  법인전환 시 꼭 필요한 정관을 통한 제도 정비</p>
				<ol class="cons_info_area">
					<li>
						<strong>법인 CEO의 법규</strong>
						<p>
							<span>• 급여</span>
							<span>• 상여</span>
							<span>• 퇴직금</span>
							<span>• 배당</span>
							<span>• 자기주식</span>
							<span>• 유족보상금</span>
						</p>
						<p class="center">
							정관
						</p>
					</li>
					<li>
						<strong>근로자의 법규</strong>
						<p>
							<span>• 최저임금</span>
							<span>• 주40 + 12(52시간)</span>
							<span>• 연장ㆍ야간ㆍ휴일수당</span>
							<span>• 육아휴직</span>
							<span>• 산재보험</span>
							<span>• 퇴직금</span>
							<span>• 주휴수당</span>
							<span>• 고용보험</span>
						</p>
						<p>
							최저임금법,<br>
							고용보험법,<br>
							모성보호법,<br>
							근로기준법,<br>
							퇴직급여보장법,<br>
							산업재해보상법,<br> 
							중대재해처벌법 등
						</p>
					</li>
				</ol>
				<div class="box cons_info_area">
					<strong>상법상 상대적 기재사항</strong>
					<ul>
						<li>
							<div class="thumb">
								<img src="/img/consulting/conversion_b/target_img_1_1.png" alt="">
							</div>
							<p>중간배당,현물배당</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/conversion_b/target_img_1_2.png" alt="">
							</div>
							<p>주식양도제한 규정</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/conversion_b/target_img_1_3.png" alt="">
							</div>
							<p>주식매수 선택권</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/conversion_b/target_img_1_4.png" alt="">
							</div>
							<p>직무발명보상</p>
						</li>
					</ul>
				</div>
				<div class="box cons_info_area">
					<strong>세법상 상대적 기재사항</strong>
					<ul>
						<li>
							<div class="thumb">
								<img src="/img/consulting/conversion_b/target_img_2_1.png" alt="">
							</div>
							<p>현물출자</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/conversion_b/target_img_2_2.png" alt="">
							</div>
							<p>이익소각</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/conversion_b/target_img_2_3.png" alt="">
							</div>
							<p>자기주식 획득</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/conversion_b/target_img_2_4.png" alt="">
							</div>
							<p>임원의 상여금 및 퇴직금</p>
						</li>
						<li>
							<div class="thumb">
								<img src="/img/consulting/conversion_b/target_img_2_5.png" alt="">
							</div>
							<p>유족보상</p>
						</li>
					</ul>
				</div>
			</div>
			<div class="cons_info_area-round">
				<div class="box_checkpoint cons_info_area">
					<p class="tit"><i><img src="/img/consulting/common/cons_info_area_checkpoint.png" alt=""></i> 개인사업자 법인전환 <strong> Check point</strong></p>
					<div class="list_desc">
						<p class="desc"><i>Check&nbsp;</i>현재 처한 회사의 사정에 따라 이익이 되는지를 고려해야 합니다.</p>
						<p class="desc"><i>Check&nbsp;</i>세금 부담은 어떻게 달라지는지 고려해야 합니다.</p>
						<p class="desc"><i>Check&nbsp;</i>회사의 업무 특성과 부합하는지 고려해야 합니다.</p>
						<p class="desc"><i>Check&nbsp;</i>기업의 자금 여건을 고려해야 합니다.</p>
						<p class="desc"><i>Check&nbsp;</i>향후 매출을 고려해야 합니다.</p>
						<p class="desc"><i>Check&nbsp;</i>비용 등을 고려해야 합니다.</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- //대상기업 -->

	<!-- 사업자 -->
	<section id="business" class="section">
		<div class="inner">
			<h3 class="tit_section">개인사업자 VS 법인사업자</h3>
			<div class="cons_info_area-round border">
				<p class="tit_cons">1. 개인사업자 VS 법인사업자</p>
				<table class="table table-gray type2 cons_info_area">
					<colgroup>
						<col style="width: auto;">
						<col style="width: auto;">
						<col style="width: auto;">
					</colgroup>
					<thead>
						<tr>
							<th>구분</th>
							<th>개인사업자</th>
							<th>법인사업자</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>설립절차와 비용 </th>
							<td class="text-left">설립철차가 비교적 쉽고, 비용이 적어 <strong>사업규모나 자본이 적은 사업에 유리</strong></td>
							<td class="text-left">법원 설립등기 절차가 필요하며 자본금과 등록면허세 등의 <strong>설립비용이 소요됨</strong></td>
						</tr>
						<tr>
							<th>사업의 책임성과 신뢰도 </th>
							<td class="text-left">경영상 발생하는 모든 문제와 부채손실에 대한 위험은 <strong>전적으로 사업주 혼자 책임</strong></td>
							<td class="text-left">법인의 <strong>주주는 출자나 지분한도 내에서만 책임</strong>을 지고 개인에 비래 대외신뢰도가 높음</td>
						</tr>
						<tr>
							<th>자금조달과 이익분배</th>
							<td class="text-left">
								<div class="bullet_area">
									<p class="pl-15"><i class="bullet">-</i>자본조달에 한계</p>
									<p class="pl-15"><i class="bullet">-</i>발생이익 활용에 제약 없음</p>
								</div>
							</td>
							<td class="text-left">
								<div class="bullet_area">
									<p class="pl-15"><i class="bullet">-</i>주식발행이나 회사채 발행을 통해 자금을 조달</p>
									<p class="pl-15"><i class="bullet">-</i>배당을 통해 이익이 분배됨</p>
								</div>
							</td>
						</tr>
						<tr>
							<th>소득처분</th>
							<td class="text-left">
								<div class="bullet_area">
									<p class="pl-15"><i class="bullet">-</i>세무조정금액이 사외로 유출된 경우</p>
									<p class="pl-15"><i class="bullet">-</i>귀속자의 소득으로 처분하지 않고 사업주가 인출하여 증여한 걷으로 봄</p>
									<p class="pl-15"><i class="bullet">-</i>세무조정금액이 기업내부에 남아있는 경우</p>
									<p class="pl-15"><i class="bullet">-</i>유보로 처분하여 유보소득조정 명세서에서 관리함</p>
								</div>
							</td>
							<td class="text-left">
								<div class="bullet_area">
									<p class="pl-15"><i class="bullet">-</i>세무조정금액이 사외로 유출된 경우</p>
									<p class="pl-15"><i class="bullet">-</i>귀속자의 소득이로 처분하여 귀속자의 소득세 납세의무 유발</p>
									<p class="pl-15"><i class="bullet">-</i>세무조정금액이 시내에 남아 있는 경우</p>
									<p class="pl-15"><i class="bullet">-</i>유보로 처분하여 관리</p>
								</div>
							</td>
						</tr>
						<tr>
							<th>대표자/사업자 급여</th>
							<td class="text-left">필요경비 불 산입</td>
							<td class="text-left">
								<div class="bullet_area">
									<p class="pl-15"><i class="bullet">-</i>손금인정</p>
									<p class="pl-15"><i class="bullet">-</i>대표에게 근로소득세부과</p>
								</div>
							</td>
						</tr>
						<tr>
							<th>대표자/사업자 퇴직급여</th>
							<td class="text-left">퇴직급여 충당금의 설정 불가</td>
							<td class="text-left">퇴직급여 충당금의 설정가능</td>
						</tr>
						<tr>
							<th>가업승계 시 자산평가</th>
							<td class="text-left">시가(실거래가)</td>
							<td class="text-left">지분승계로 비상장주식 평가법 적용</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="cons_info_area-round border">
				<p class="tit_cons">2. 개인사업자 VS 법인사업자 세율 비교</p>
				<table class="table table-gray cons_info_area">
					<colgroup>
						<col style="width: auto;">
						<col style="width: auto;">
						<col style="width: auto;">
						<col style="width: auto;">
					</colgroup>
					<thead>
						<tr>
							<th>소득구간</th>
							<th>개인사업자 세율</th>
							<th>소득구간</th>
							<th>법인사업자 세율</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1,200만 이하</td>
							<td>6%</td>
							<td>2억원 이하</td>
							<td>10%</td>
						</tr>
						<tr>
							<td>4,600만 이하</td>
							<td>15%</td>
							<td>2억 ~ 200억원 이하</td>
							<td>20%</td>
						</tr>
						<tr>
							<td>8,800만 이하</td>
							<td>24%</td>
							<td>200억원 이하</td>
							<td>22%</td>
						</tr>
						<tr>
							<td>1억 5천만 이하</td>
							<td>35%</td>
							<td>3000억원 초과</td>
							<td>25%</td>
						</tr>
						<tr>
							<td>3억 이하</td>
							<td>38%</td>
							<td>-</td>
							<td>-</td>
						</tr>
						<tr>
							<td>5억 이하</td>
							<td>40%</td>
							<td>-</td>
							<td>-</td>
						</tr>
						<tr>
							<td>5억 이상</td>
							<td>42%</td>
							<td>-</td>
							<td>-</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="cons_info_area-round border">
				<p class="tit_cons">3. 개인사업자 법인전환 방법별 주요 세금</p>
				<table class="table table-gray cons_info_area">
					<colgroup>
						<col style="width: auto;">
						<col style="width: auto;">
						<col style="width: auto;">
						<col style="width: auto;">
					</colgroup>
					<thead class="thead-multiline">
						<tr>
							<th rowspan="2">구분</th>
							<th colspan="3">법인전환방법</th>
						</tr>
						<tr>
							<th>세 감면 포괄양수도</th>
							<th>현물출자</th>
							<th>일반사업양수도</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>부가가치세</th>
							<td>과세제외</td>
							<td>과세제외</td>
							<td>과세</td>
						</tr>
						<tr>
							<th>양도소득세</th>
							<td>이월과세</td>
							<td>이월과세</td>
							<td>과세</td>
						</tr>
						<tr>
							<th>부동산 등 취득세</th>
							<td>면제</td>
							<td>면제</td>
							<td>과세</td>
						</tr>
						<tr>
							<th>차량 등 취득세</th>
							<td>면세</td>
							<td>면세</td>
							<td>과세</td>
						</tr>
						<tr>
							<th>법인성립 등록면허세</th>
							<td>과세</td>
							<td>과세</td>
							<td>과세</td>
						</tr>
						<tr>
							<th>국민주택 채권</th>
							<td>적액매입</td>
							<td>적액매입</td>
							<td>적액매입</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</section>
	<!-- //사업자 -->

	<!-- 컨설팅 프로세스 -->
	<consultingProcess_b data-list-idx="1" data-title="컨설팅 프로세스" />
	<!-- //컨설팅 프로세스 -->

	<!-- 전문가 네트워크 -->
	<networkTab class="is-event-none" />
	<!-- //전문가 네트워크 -->

	<!-- 무료 컨설팅 상담신청 -->
	<section id="consulting" class="section"></section>
	<!-- //무료 컨설팅 상담신청 -->

	<!-- 컨설팅 분야 바로가기 -->
	<consultingField_b />
	<!-- //컨설팅 분야 바로가기 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
export default {
	data() {
		return {
			imgPath: '/img/consulting/conversion_b/'
		}
	}
}
</script>



<style scoped lang="scss">
	@import '@/assets/scss/consulting/conversion_b.scss';
</style>