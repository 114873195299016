<template>
<header>
    <div class="gnb_layer">
        <div class="inner">
            <a class="btn_kakako" href="http://pf.kakao.com/_HzxfKs/chat" target="_blank"></a>
            <nav class="clearfix">
                <div class="layer_cate">
                    <strong data-text="title"></strong>
                    <ul>
                        <li><a></a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
    <div class="gnb_wrap inner">
        <nav class="gnb">
            <div class="cate">
                <strong data-text="title"></strong>
                <div class="list">
                    <ul>
                        <li><a></a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="frm frm1">
            <button type="button" class="toggleMenu"><i></i><i></i><i></i></button>
            <button type="button" class="toggleLayer btn1">무료컨설팅 상담신청</button>
            <div class="frm frm2">
                <h2><a href="/" title="메인으로 이동하기"></a></h2>
                <button type="button" class="toggleLayer btn2">무료컨설팅 상담신청</button>
            </div>
        </div>
    </div>
    <div class="form_layer">
        <img class="medal" src="/img/main/img04_medal.png" alt="">
        <em class="medal_desc">(주최 : 주간동아)</em>
        <button type="button" class="close" data-json-path="/image/common/btnClose01.png"></button>
        <p>
            <strong>무료 컨설팅 상담신청</strong>
            <span>
                매경경영지원본부에서는 그간 축적된 노하우와<br>
                경험을 바탕으로 <b>세무법인, 법무법인, 노무법인 등을 통하여</b><br>
                <b>전문적인 법무 세무 노무 특허 등 다양한 서비스를 제공</b>합니다.
            </span>            
        </p>

        <div class="loadform">
            <li class="row row_type row_css" data-row="type" data-check="true" data-check-pass="false" data-check-prop="select" data-inbound-prop="consultCodeId" data-check-comment="상담분야를 선택하세요">
                <label>
                    <div class="select_wrap">
                        <select class="select_css">
                            <option value="">상담분야를 선택하세요</option>
                            <option value="238">자사주매입</option>
                            <option value="246">(가족법인활용)자산이전</option>
                            <option value="239">지배구조개선</option>
                            <option value="193">가업승계전략</option>
                            <option value="189">차명주식회수</option>
                            <option value="190">가지급금정리</option>
                            <option value="191">배당정책</option>
                            <option value="192">법인전환</option>
                            <option value="247">인증/정책자금/경정청구</option>
                            <option value="248">병/의원컨설팅</option>
                            <option value="240">기타</option>
                        </select>
                        <u class="select_arrow theme_border_left_color theme_border_bottom_color"></u>
                    </div>
                </label>
            </li>
        </div>
    </div>
    </header>
</template>


<style lang="scss">
    
</style>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>


