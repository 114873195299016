<template>
<!-- main -->
<main id="container">
    <!-- section 위치표시 및 바로가기 -->
    <nav id="nav_container">
        <div class="list_nav">
            <a class="link_nav" data-target="#visual"><i></i><span>Main</span></a>
            <a class="link_nav" data-target="#example"><i></i><span>컨설팅 사례</span></a>
            <a class="link_nav" data-target="#problem"><i></i><span>차명주식 문제점</span></a>
            <a class="link_nav" data-target="#collect"><i></i><span>회수 방법</span></a>
            <a class="link_nav" data-target="#consultingProcess_b"><i></i><span>컨설팅 프로세스</span></a>
            <a class="link_nav" data-target="#networkTab"><i></i><span>전문가 네트워크</span></a>
            <a class="link_nav" data-target="#consulting"><i></i><span>무료 상담신청</span></a>
            <a class="link_nav" data-target="#consultingField_b"><i></i><span>바로가기</span></a>
        </div>
    </nav>
    <!-- //section 위치표시 및 바로가기 -->

    <!-- 메인 -->
    <section id="visual" data-type="b">
        <div class="inner">
            <h3 class="tit_section">차명주식회수 <span>(= 명의신탁주식)</span> </h3>
            <p class="desc_section">
                차명주식, 또는 명의신탁주식이란 실제 소유자가 다른 사람의 명의를 빌려 보유한 주식을 뜻합니다.<br>
                <mark>차명주식을 회수하지 않는다면</mark> 차명주주와의 관계가 악화됨에 따라, 또는 차명주주가 사망함에 따라<br>
                <mark>그 주식에 대한 재산권 분쟁, 회사의 경영권 간섭 등 여러 가지 문제가 발생</mark>할 수 있습니다.
            </p>
            <button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span><img class="ico" src="" data-json-path="/image/svg/arr_right02.svg"></button>
            <div class="list_visual">
                <button class="btn_visual" data-target="#example" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img01.png" alt="">
                    <p class="tit">컨설팅 사례</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#problem" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img02.png" alt="">
                    <p class="tit">차명주식 문제점</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#collect" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img03.png" alt="">
                    <p class="tit">회수 방법</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#consultingProcess_b" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img04.png" alt="">
                    <p class="tit">컨설팅 프로세스</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#consulting" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img05.png" alt="">
                    <p class="tit">무료 상담신청</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
            </div>
        </div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="section" data-type="b">
        <div class="inner">
            <h3 class="tit_section">차명주식회수 컨설팅 사례</h3>
            <div class="list_talk">
                <div class="item_talk left">
                    <p class="tit_talk color-ffffff" style="color:#fff;">의뢰인</p>
                    <img class="profile" src="/img/consulting/common/example_talk_profile_client1.png" alt="">
                    <div class="tooltip">
                        <p class="tit">문제점</p>
                        <p>명의수탁자는 작은 회사로 시작되었기 때문에 관심이 없다가 세월이 지나 회사의 규모가 커지고 주식의 가치가 높아진 것을 보고 소유권 주장하였습니다.</p>
                    </div>
                    <button class="videoLayer" data-src="https://www.youtube.com/embed/kG9hyBjbuf4" data-title="차명주식의 문제점 및 효율적인 회수방법" data-type="youtube" type="button" title="영상재생">
                        <img class="thumb" src="https://img.youtube.com/vi/kG9hyBjbuf4/maxresdefault.jpg" alt="스크린샷">
                    </button>
                </div>
                <div class="item_talk right">
                    <div class="profile">
                        <img src="/img/consulting/collection_b/example_talk_profile_professional1.png" alt="">
                        <span class="profile_name">컨설팅 전문가</span>
                    </div>
                    <div class="tooltip">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>차명주식 신탁 당시 작성된 명의신탁 약정서를 찾아내어 주주확인  소송을 진행하고 관할 세부서를 통해 명의신탁 해지를 접수하여  완료하였습니다. </li>
                            <li>지원본부 소속의 세무사와 변호사의 발빠른 대응으로 주주확인 소송과 명의신탁 해지 절차로 마무리가 되었습니다</li>
                        </ol>
                    </div>
                </div>
                <div class="item_talk right lst">
                    <div class="tooltip">
                        <p class="tit">결론</p>
                        <p>의뢰인과의 정확한 이해와 빠른 판단 그리고 지원본부 소속의 전문가 그룹과의 발빠른 대응으로 안정적으로 신탁을 회수 할 수 있었습니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 차명주식 문제점 -->
    <section id="problem" class="section">
        <div class="inner">
            <h3 class="tit_section">차명주식 문제점</h3>
            <div class="cons_info_area-round">
                <p class="tit_cons">1. 명의수탁자의 신변의 변화에 따른 문제가 발생할 수 있다.</p>
                <p class="desc_cons">명의수탁자의 신변의 변화에 자인 명의신탁자가 소유권을 주장하기 어려운 상황이 발생할 수 있습니다. </p>
                <ul class="list_thumb cons_info_area">
                    <li class="item_thumb">
                        <img class="thumb" src="/img/consulting/collection_b/problem_thumb1-1.jpg" alt="">                        
                        <p class="tit">명의수탁자 <em>사망 시,</em></p>
                        <p class="desc">해당 차명주식을 상속받은 배우자<br> 또는 자녀가 소유권을 주장할 수 있습니다. </p>
                    </li>
                    <li class="item_thumb">
                        <img class="thumb" src="/img/consulting/collection_b/problem_thumb1-2.jpg" alt="">                        
                        <p class="tit">명의수탁자 <em>신용 문제 시,</em></p>
                        <p class="desc">차명주식을 압류 당할 수도 있으며,<br> 명의수탁자가 변심하여 소유권을<br> 주장할 수도 있습니다. </p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area-round justify-content-between">
                <div class="left cons_info_area">
                    <p class="tit_cons">2. 차명주식의 명의자와 실제소유자가 다르기 때문에 문제가 발생할 수 있다.</p>
                    <p class="desc_cons">실제소유자인 명의신탁자의 가업상속계획, 지분이동계획 등에 제약이 있을 수 있습니다.<br> 또한, 이러한 제약 때문에 차명주식을 회수하는데 드는 거래비용 (증여세, 양도소득세, 간주취득세 등 세금부담)이 발생할 수도 있습니다.</p>
                </div>
                <img class="thumb" src="/img/consulting/collection_b/problem_thumb2.jpg" alt="">                
            </div>
            <div class="cons_info_area-round justify-content-between">
                <div class="left cons_info_area">
                    <p class="tit_cons">3. 차명주식의 보유 자체만으로 세무상 위험을 가지게 된다.</p>
                    <p class="desc_cons mb-15">과세당국은 <strong>차명주식의 편법증여나 주가조작을 통한 불공정거래에 차명주식을 이용하여<br> 탈세를 한 경우</strong> 끝까지 추적하여 적발하고 있으며, 세금 추징 또한 엄격하게 하고 있습니다. </p>
                    <p class="desc_cons">또한, 새로운 국세행정시스템 NTIS의 정보 분석기능을 통해 차명주식을<br> 추적하면서 양도 및 증여가 있었는지 확인하고 탈세 여부를 추적하여<br> 세금을 부과하고 있으며, <strong>차명주식 보유로 인해 세무조사 대상</strong>이 되기도 합니다.</p>
                </div>
                <img class="thumb" src="/img/consulting/collection_b/problem_thumb3.jpg" alt="">                
            </div>
        </div>
    </section>
    <!-- //차명주식 문제점 -->

    <!-- 회수방법 -->
    <section id="collect" class="section">
        <div class="inner">
            <h3 class="tit_section">회수 방법</h3>
            <div class="cons_info_area-round border">
                <p class="tit_cons">1. 차명주식을 명의수탁자로부터 증여하거나 양도하는 방법</p>
                <ul class="list_thumb cons_info_area">
                    <li class="item_thumb">
                        <img class="thumb" src="/img/consulting/collection_b/collect_thumb1.jpg" alt="">                        
                        <p class="tit">주식 <em>증여(수증)</em> 활용</p>
                        <p class="desc">실제 소유자인 명의신탁자가 증여세를<br> 납부하여야 합니다.</p>
                    </li>
                    <li class="item_thumb">
                        <img class="thumb" src="/img/consulting/collection_b/collect_thumb2.jpg" alt="">                        
                        <p class="tit">주식 <em>양도</em> 활용 </p>
                        <p class="desc">실제소유자인 명의신탁자가 주식 양수도<br> 대금을 명의수탁자에게 지급하여야 하고,<br> 명의수탁자는 양도소득세를 납부합니다. </p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area-round border">
                <p class="tit_cons">2. 차명주식 실제소유자 확인제도를 이용하는 방법</p>
                <p class="desc_cons">차명주식 실제소유자 확인제도는 간소화 된 서류를 통해 명의신탁주식을 실제소유자에게 환원할 수 있도록 과세 당국이 지원하는 제도입니다.</p>
                <div class="box_round cons_info_area">
                    <p class="tit">차명주식 실제소유자 확인제도 <strong>자격 조건</strong></p>
                    <div class="desc">
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">조세특례제한법 상 중소기업에 해당 되어야 함</p>
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">2001년 7월 23일 이전에 법인을 설립한 기업</p>
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">실제 소유자와 명의수탁자 모두 법인 설립 당시 발기인이어야 함</p>
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">소유자 별, 주식발행 법인별로 실명 전환하는 주식가액의 합계약이 30억원 미만이어야 함</p>
                    </div>
                </div>
                <div class="box_round cons_info_area">
                    <p class="tit">차명주식 실제소유자 확인제도 <strong>신청 절차</strong></p>
                    <div class="desc pl-0">
                        <img class="d-block mx-auto" src="/img/consulting/collection_b/collect_step.png" alt="">                    
                    </div>
                    <p class="cmt">* 단, 이 제도는 납세의무를 면제해주는 제도가 아니고 명의신탁 된 주식을 실명 전환하는 과정을 간소화하여 인정해 주는 제도일 뿐이라<br> 실제 인정을 받더라도 차명당시의 주식가치에 대한 증여세와 차명으로 존재하는 기간 동안의 가산세를 부담하여야 합니다. </p>
                </div>
            </div>
            <div class="cons_info_area-round border">
                <p class="tit_cons">3. 차명주식의 실제소유자인 명의신탁자가 과세당국에 명의신탁 해지신청을 하는 방법</p>
                <div class="box_round cons_info_area">
                    <p class="tit">차명주식이라는 사실을 입증하기 위한 명의신탁자 <strong>증빙 제출 서류</strong> </p>
                    <ul class="list_submit">
                        <li class="item_submit">
                            <img src="/img/consulting/collection_b/collect_submit1.png" alt="">
                            <p>명의신탁주식 실<br>소유자 확인서</p>
                        </li>
                        <li class="item_submit">
                            <img src="/img/consulting/collection_b/collect_submit2.png" alt="">
                            <p>명의신탁주식 실<br>소유자 확인서</p>
                        </li>
                        <li class="item_submit">
                            <img src="/img/consulting/collection_b/collect_submit3.png" alt="">
                            <p>명의신탁주식 실<br>소유자 확인서</p>
                        </li>
                        <li class="item_submit">
                            <img src="/img/consulting/collection_b/collect_submit4.png" alt="">
                            <p>명의신탁주식 실<br>소유자 확인서</p>
                        </li>
                    </ul>
                </div>
                <div class="list_submit-footer">
                    <p>과세당국에 제출<span>* 명의신탁 당시에 증여세 발생할 수 있음</span></p>
                    <img src="/img/consulting/collection/collect_submit_footer.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- //회수방법 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess_b data-list-idx="1" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="section"></section>
    <!-- //무료 컨설팅 상담신청 -->

    <!-- 컨설팅 분야 바로가기 -->
    <consultingField_b />
    <!-- //컨설팅 분야 바로가기 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){




        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/collection_b.scss';
</style>