<template>
<!-- main -->
<main id="container">
    <!-- section 위치표시 및 바로가기 -->
    <nav id="nav_container">
        <div class="list_nav">
            <a class="link_nav" data-target="#visual"><i></i><span>Main</span></a>
            <a class="link_nav" data-target="#example"><i></i><span>컨설팅 사례</span></a>
            <a class="link_nav" data-target="#plan"><i></i><span>가지급금 정리방안</span></a>
            <a class="link_nav" data-target="#risk"><i></i><span>발생원인 및 리스크</span></a>
            <a class="link_nav" data-target="#consultingProcess"><i></i><span>컨설팅 프로세스</span></a>
            <a class="link_nav" data-target="#networkTab"><i></i><span>전문가 네트워크</span></a>
            <a class="link_nav" data-target="#consulting"><i></i><span>무료 상담신청</span></a>
            <a class="link_nav" data-target="#consultingField"><i></i><span>바로가기</span></a>
        </div>
    </nav>
    <!-- //section 위치표시 및 바로가기 -->

    <!-- 메인 -->
    <section id="visual" data-type="a">
        <div class="inner">
            <h3 class="tit_section">가지급금 정리 </h3>
            <p class="desc_section">
                가지급금이란 법인에서 <mark>실제 현금의 지출은 있었지만 거래 내용이 불분명하거나 완전히 종결되지 않아 금액이 확정되지 않은 경우</mark>에<br>
                그 지출액에 대해 임시적으로 표시하는 가계정입니다. <mark>접대비, 출장비, 리베이트 비용, 특수관계인의 사적 용도의 경비 지출</mark> 등으로 발생하며,<br>
                상당수의 법인 대표들은 회사에 가지급금이 얼마나 있는지 모르는 경우가 많습니다.
            </p>
            <button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span></button>
            <div class="list_visual">
                <button class="btn_visual" data-target="#example" type="button">
                    <img class="ico" :src="imgPath+'visual_ico1.png'" alt="">
                    <p class="tit">컨설팅 사례</p>
                    <p class="desc">전문가 컨설팅 사례를<br>영상으로 확인해보세요!</p>
                </button>
                <button class="btn_visual" data-target="#plan" type="button">
                    <img class="ico" :src="imgPath+'visual_ico2.png'" alt="">
                    <p class="tit">가지급금 정리방안</p>
                    <p class="desc">세부담을 합법적으로<br> 최소화할수있는 방안 확인!</p>
                </button>
                <button class="btn_visual" data-target="#risk" type="button">
                    <img class="ico" :src="imgPath+'visual_ico3.png'" alt="">
                    <p class="tit">발생원인 및 리스크</p>
                    <p class="desc">기업 자금의 누수 주범인<br> 가지급금의 리스크 점검</p>
                </button>
                <button class="btn_visual" data-target="#consultingProcess" type="button">
                    <img class="ico" :src="imgPath+'visual_ico4.png'" alt="">
                    <p class="tit">컨설팅 프로세스</p>
                    <p class="desc">가지급금 정리 절차<br> 확인! </p>
                </button>
                <button class="btn_visual" data-target="#consulting" type="button">
                    <img class="ico" :src="imgPath+'visual_ico5.png'" alt="">
                    <p class="tit">무료 상담신청</p>
                    <p class="desc">이제 고민은 그만!<br>전문가가 해결해드립니다. </p>
                </button>
            </div>
            <div class="particle_circle" style="bottom:15px;right:450px"></div>
            <div class="particle_circle-border" style="top:-130px;right:470px"></div>
        </div>
        <div class="particle_circle-double" style="bottom:-170px;left:80px;width:270px;height:270px"></div>
        <div class="particle_circle-double" style="top:10px;right:-120px;width:290px;height:290px"></div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="section" data-type="a">
        <div class="inner">
            <h3 class="tit_section">가지급금정리 컨설팅 사례 </h3>
            <button class="videoLayer" data-src="https://www.youtube.com/embed/UDmIjLVIfBo" data-title="효율적인 가지급금의 처리방안은?" data-type="youtube" type="button" title="영상재생">
                <img class="thumb" src="https://img.youtube.com/vi/UDmIjLVIfBo/maxresdefault.jpg" alt="스크린샷">
                <div class="particle_circle" style="top:-40px;left:-30px;width:75px;height:75px"></div>
                <div class="particle_circle-border" style="bottom:-20px;right:-90px;width:66px;height:66px"></div>
            </button>
            <div class="list_talk">
                <div class="item_talk left">
                    <p class="tit_talk">의뢰인</p>
                    <img class="profile" src="/img/consulting/collection/example_talk_profile_client1.jpg" alt="">
                    <div class="tooltip">
                        <p class="tit">컨설팅 문의 </p>
                        <p>제조업으로 과거 운영 과정에서 불가피하게 70여억원에 이르는<br> 가지급금이 발생되어 이를 해결하기를 원합니다. </p>
                    </div>
                </div>
                <div class="item_talk right">
                    <p class="tit_talk">컨설팅 전문가</p>
                    <img class="profile" src="/img/consulting/collection/example_talk_profile_professional1.jpg" alt="">
                    <div class="tooltip">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>지원본부 소속의 세무사와 변호사와의 팀플레이를 통해 상법상<br>적법한 절차 범위 내에서 산업재산권과 자기주식을 매입하였으며,<br>배당이 가능한 이익의 범위 내에서 가지급금 상계처리를<br>진행하였습니다.</li>
                            <li>적은 세금으로 70억원의 가지급금 해결 및 소명하였습니다.</li>
                            <li>이에 따라 회사의 부실자산이 줄어들면서 재무구조 개선과<br>신용도가 향상되었으며 또한 인정이자가 영업 외 이익으로 잡히게<br>되어 추가로 납부하던 법인세 경감의 효과를 얻었습니다.</li>
                        </ol>
                    </div>
                </div>
                <img class="bg" src="/img/consulting/collection/example_talk_bg.png" alt="">
            </div>
        </div>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 정리방안 -->
    <section id="plan" class="section">
        <div class="inner">
            <h3 class="tit_section">가지급금 정리방안</h3>
            <div class="cons_info_area">
                <div class="box_tit"> 가지급금의 정리방안으로는 <strong>단순한 회계/세무 오류인 경우 전기오류수정회계를 활용</strong>하고,<br> <strong> 급여 또는 상여를 인상, 배당금 활용, 퇴직금, 증여를 활용한 주식의 소각, 직무발명 보상금,<br> 특허권 매매 등의 방법으로 정리 </strong>할 수 있습니다. 허나 그 과정이 상당히 <strong>전문적이고 어려워 더 큰 세금 문제나<br> 법률 위반 문제로 번질 수 있기 때문에 반드시 관련된 전문가의 도움을 받는 것을 추천</strong>합니다. </div>
            </div>
            <div class="cons_info_area area2 mb-70">
                <ul class="list_box">
                    <li class="item_box">
                        <p class="tit">대표이사 개인자산<br> 매각 </p>
                        <p class="desc">대표이사의 개인자산인<br> 현금, 부동산, 주식 등을<br> 매각해서 상환</p>
                    </li>
                    <li class="item_box">
                        <p class="tit">대표이사 상여, 급여로<br> 처리</p>
                        <p class="desc">대표이사의 소득세 및<br> 4대 보험료 증가</p>
                    </li>
                    <li class="item_box">
                        <p class="tit">배당정책을 통한<br> 배당수익으로 처리</p>
                        <p class="desc">대표의 종합소득세 증가 </p>
                    </li>
                    <li class="item_box">
                        <p class="tit">대표이사 퇴직금으로<br> 처리</p>
                        <p class="desc">퇴직소득의 한도와 조건 필요 </p>
                    </li>
                    <li class="item_box">
                        <p class="tit">산업재산권으로 처리 </p>
                        <p class="desc">대표의 특허권, 디자인권,<br> 상표권, 영업권 등을 법인에<br> 양도로 상환</p>
                    </li>
                    <li class="item_box">
                        <p class="tit">자기주식 취득으로 처리 </p>
                        <p class="desc">대표 자신의 주식을<br> 법인에 양도하여 처리 </p>
                    </li>
                    <li class="item_box">
                        <p class="tit">법인 이익잉여금<br> 활용 처리</p>
                        <p class="desc">사내 유보하고 있는 법인<br> 이익잉여금으로 상환 </p>
                    </li>
                </ul>
                <img class="bg" :src="imgPath+'plan_bg.png'" alt="">
            </div>
            <div class="cons_info_area mb-30">
                <div class="box_checkpoint">
                    <p class="tit">가지급금 <strong><img class="ico" src="" data-json-path="/image/svg/check01.svg">체크 포인트</strong></p>
                    <div class="list_desc">
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">법인자산 매도 시 업무와 관련이 있는 자산이어야 다시 가지급금으로 인정되거나 상여 처분이 되지 않습니다.</p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">산업재산권을 활용할 경우 업무유관 자산인지, 대표가 직접 개발한 것인지, 기존 법인특허의 상관관계 등을 고려해야 합니다.</p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg">산업재산권, 자사주 매입, 특허권을 활용할 경우 ‘특허권 및 비상장주식 가치평가’ 가 중요합니다.</p>
                    </div>
                    <img class="clip" src="/img/common/ico/clip.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- //정리방안 -->

    <!-- 리스크 -->
    <section id="risk" class="section">
        <div class="inner">
            <h3 class="tit_section">가지급금 발생원인 및 리스크</h3>
            <div class="cons_info_area">
                <p class="tit_cons">1. 가지급금 발생 원인</p>
                <p class="desc_cons mb-55">중소기업의 일부 대표들은 자신이 법인으로부터 가져간 금액이 없는데도 불구하고 재무상태표에 가지급금이 있어 의아해하는 경우가 종종 있습니다.<br> <strong>가지급금을 장기간 상환하지 않는 경우</strong> 세법상 불이익과 더불어 <strong>세부조사</strong>를 받을 수 있고, <strong>배임 및 횡령으로 형사고발</strong>까지 이어질 수 있으니<br> <strong>정확한 발생원인을 파악하고 해결</strong>해야 합니다.</p>
                <ul class="list_graph">
                    <li class="item_graph">
                        <img :src="imgPath+'risk_graph1.png'" alt="">
                        <p class="tit">대표이사 및 임원,<br>주주에게 자금대여</p>
                    </li>
                    <li class="item_graph">
                        <img :src="imgPath+'risk_graph2.png'" alt="">
                        <p class="tit">대표이사 및 임원의<br> 개인적 비용 지출</p>
                    </li>
                    <li class="item_graph">
                        <img :src="imgPath+'risk_graph3.png'" alt="">
                        <p class="tit">일용직 노동자 임금,<br> 접대비, 리베이트 등<br> 증빙 처리하지 못한<br> 지출 </p>
                    </li>
                    <li class="item_graph">
                        <img :src="imgPath+'risk_graph4.png'" alt="">
                        <p class="tit">실무자의<br> 회계적 실수</p>
                    </li>
                    <li class="item_graph">
                        <img :src="imgPath+'risk_graph5.png'" alt="">
                        <p class="tit">관계회사에<br> 자금 대여</p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area area2 mb-75">
                <p class="tit_cons">2. 법인 가지급금 리스크</p>
                <p class="desc_cons mb-35">가지급금의 인정이자를 납부하지 않으면 <strong>상여처리 되어 대표자의 소득세를 증가시키고 미납한 인정이자 만큼 가지급금의 증가로 인한 위험을 가중</strong>시킵니다.<br> 이러한 위험은 <strong>특수관계가 소멸되기 전까지 매년 발생</strong>하고 가지급금의 지속적 보유는 향후 상속.증여세 또한 증가시킵니다.<br> <strong> 회수 가능성이 없는 가지급금임에도 불구하고 회사에서는 자산으로 계속 보유하고 있기 때문에 주식가치를 증가시켜 주식 이동 시<br> 과동한 세금을 발생 </strong>시키기도 합니다.</p>
                <ul class="list_info">
                    <li class="item_info">
                        <p class="tit">인정이자 발생</p>
                        <p class="desc">매년 4.6%의 인정이자 발생 </p>
                    </li>
                    <li class="item_info">
                        <p class="tit">인정이자 만큼 법인세 증가 </p>
                        <p class="desc">인정이자 만큼 매년 익금산입되어 법인세 증가 </p>
                    </li>
                    <li class="item_info">
                        <p class="tit">인정이자 미납 시 대표<br> 상여처리</p>
                        <p class="desc">인정이자 미납 시 대표의 상여로 처리되어 소득세 및 4대 보험료 증가 </p>
                    </li>
                    <li class="item_info">
                        <p class="tit">지급이자 손금불산입</p>
                        <p class="desc">차입금이 있을 경우 가지급금 비율만큼 차입금 이자비용 손금불산입</p>
                    </li>
                    <li class="item_info">
                        <p class="tit">대손처리 불가능</p>
                        <p class="desc">미상환 할 경우 법인손실로 대손처리 불가능. 임의로 대손처리 시 횡령 및 배임죄 적용 가능</p>
                    </li>
                    <li class="item_info">
                        <p class="tit">세무조사의 위험성 증가 </p>
                        <p class="desc">과세당국은 가지급금을 비정상적 지출로 간주하므로, 세무조사에 대한 위험성 증가</p>
                    </li>
                    <li class="item_info">
                        <p class="tit">기업신용평가 시 불이익</p>
                        <p class="desc">자금조달이 필요할 경우 기업신용평가 시 감점 요인으로 작용해 이자 부담 가중</p>
                    </li>
                    <li class="item_info">
                        <p class="tit">가업승계 시 상속 및<br> 증여세 부담 가중</p>
                        <p class="desc">가지급금을 상속재산으로 간주해 상속세 증가. 비상장주식평가 시 자산으로 평가되어 상속 및 증영세 증가</p>
                    </li>
                    <li class="item_info">
                        <p class="tit">양도/폐업 시 대표 상여처리</p>
                        <p class="desc">양도나 폐업 시에 원금과 이자에 대해 대표이사 상여 처리되어 소득세 증가</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- //리스크 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="1" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="section"></section>
    <!-- //무료 컨설팅 상담신청 -->

    <!-- 컨설팅 분야 바로가기 -->
    <consultingField />
    <!-- //컨설팅 분야 바로가기 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/payment/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/payment.scss';
</style>