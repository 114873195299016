<template>
<!-- main -->
<main id="container">
    <!-- section 위치표시 및 바로가기 -->
    <nav id="nav_container">
        <div class="list_nav">
            <a class="link_nav" data-target="#visual"><i></i><span>Main</span></a>
            <a class="link_nav" data-target="#example"><i></i><span>컨설팅 사례</span></a>
            <a class="link_nav" data-target="#problem"><i></i><span>자사주매입 효과</span></a>
            <a class="link_nav" data-target="#collect"><i></i><span>자사주 과세문제</span></a>
            <a class="link_nav" data-target="#consultingProcess_b"><i></i><span>자사주매입 실행 절차</span></a>
            <a class="link_nav" data-target="#networkTab"><i></i><span>전문가 네트워크</span></a>
            <a class="link_nav" data-target="#consulting"><i></i><span>무료 상담신청</span></a>
            <a class="link_nav" data-target="#consultingField_b"><i></i><span>바로가기</span></a>
        </div>
    </nav>
    <!-- //section 위치표시 및 바로가기 -->

    <!-- 메인 -->
    <section id="visual" data-type="b">
        <div class="inner">
            <h3 class="tit_section">자사주 매입</h3>
            <p class="desc_section">
                자사주 매입이란 기업이 발행한 자기 회사의 주식을 다시 취득하는 것을 말합니다.<br>
                자기주식 취득 제도가 기업의 절세 플랜으로 자주 언급되는 이유는,<br>
                <mark>세금을 줄이면서 주식 이동이 가능해 각종 기업의 경영리스크를 해결</mark> 할 수 있기 때문입니다.
            </p>
            <button class="btn_cont" type="button"><span>무료 컨설팅 상담신청</span><img class="ico" src="" data-json-path="/image/svg/arr_right02.svg"></button>
            <div class="list_visual">
                <button class="btn_visual" data-target="#example" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img01.png" alt="">
                    <p class="tit">컨설팅 사례</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#problem" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img02.png" alt="">
                    <p class="tit">자사주매입 효과</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#collect" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img03.png" alt="">
                    <p class="tit">자사주 과세문제</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#consultingProcess_b" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img04.png" alt="">
                    <p class="tit">자사주매입 실행 절차</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
                <button class="btn_visual" data-target="#consulting" type="button">
                    <img class="thumb" src="/img/consulting/certify_b/visual_img05.png" alt="">
                    <p class="tit">무료 상담신청</p>
                    <img class="ico" src="" data-json-path="/image/svg/next01-bold.svg" alt="">
                </button>
            </div>
        </div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="section" data-type="b">
        <div class="inner">
            <h3 class="tit_section">자사주 매입 활용방안</h3>
            <div class="list_talk">
                <div class="item_talk">
                    <button class="videoLayer" data-src="https://www.youtube.com/embed/TkJQfZF79js" data-title="보유목적 자사주 관련 쟁점판례 및 활용방안" data-type="youtube" type="button" title="영상재생">
                        <img class="thumb" src="https://img.youtube.com/vi/TkJQfZF79js/maxresdefault.jpg" alt="스크린샷">
                    </button>
                </div>
                <div class="item_solution">
                    <div class="profile">
                        <p class="tit_talk color-ffffff" style="color:#fff;">의뢰인1</p>
                        <img src="/img/consulting/common/example_talk_profile_client1.png" alt="">
                    </div>
                    <div class="tooltip">
                        <p class="tit">문제점</p>
                        <p>배우자가 유고상황이고 자녀앞으로 자본환원을 원합니다. 미처분이익잉여금 40억은 주주에게 배당으로 해결하고 있으나 소득세 및 건강보험료 폭탄으로 주주들의 불만이 발생하고있습니다.</p>
                    </div>
                    <div class="answer">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>양도소득세 이월과세를 대상에서 제외하면 소각대가와 취득가액이 동일하여 소득세가 발생하지 않습니다.</li>
                            <li>배당소득세 VS 증여세 비교 검토 후 이익소각으로 절세할 수 있습니다.</li>
                            <li>본인의 주식 중 5억에 해당되는 주식을 자녀에게 증여 후 자사주 매입을 통해 미처분이익잉여금 해소 및 주식가치 인하 효과를 기대할 수 있습니다.</li>
                            <li>주주자본 환원으로 최대주주의 의결권을 극대화 할 수있습니다.</li>
                        </ol>
                    </div>
                </div>
                <div class="item_solution">
                    <div class="profile">
                        <p class="tit_talk" style="color:#303030">의뢰인2</p>
                        <img src="/img/consulting/common/example_talk_profile_client2.png" alt="">
                    </div>
                    <div class="tooltip">
                        <p class="tit type2">컨설팅 문의</p>
                        <p>가업승계가 아닌 외부투자유치 및 핵심 임직원을 위한 자사주매입에 대해 문의합니다.</p>
                    </div>
                    <div class="answer">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>대표이사 본인의 주식을 보유목적의 자기주식 취득 실행으로 양도차익에 대한 양도소득세만 납부하여 분류과세를 통한 절세효과를 받을 수 있습니다.</li>
                            <li>핵심 임직원에 대한 주식보상을 법인에서는 상여로 처리하여 법인세 절감 효과를 받을 수 있습니다.</li>
                            <li>주식을 부여받은 주주들에게 차등배당을 실행하여 이익이여금을 해소합니다</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 자사주매입 효과 -->
    <section id="problem" class="section">
        <div class="inner">
            <h3 class="tit_section">자사주매입 효과</h3>
            <div class="cons_info_area-round">
                <p class="tit_cons">1. 자사주 매입 절세 효과</p>
                <ul class="arrow_list">
                    <li>
                        <div class="illust">
                            <img :src="imgPath+'purchase_img01.png'" alt="">
                            <p class="tit">자사주<br> 매입할 경우</p>
                        </div>
                        <img class="arrow_gray" src="/img/consulting/common/cons_info_area_gray_arrow.png" alt="">
                        <p class="desc">기업, 주주, 임직원 모두 절세효과의 혜택을 받을 수 있습니다.</p>
                    </li>
                    <li>
                        <div class="illust">
                            <img :src="imgPath+'purchase_img02.png'" alt="">
                            <p class="tit">주주나<br> 임원의 경우</p>
                        </div>
                        <img class="arrow_gray" src="/img/consulting/common/cons_info_area_gray_arrow.png" alt="">
                        <p class="desc" style="color:#505056;">세법 상 다른 소득과 분류과세로 적용됩니다.<br> 20%(중소기업 소액주주의 경우 10%) 단일세율에 의한 과세, 4대 보험료와 관계는 없습니다. <br> 주식의 소유권이 법인으로 변경되어 상속대상 재산에서 제외 되므로 절세가 가능합니다.</p>
                    </li>
                    <li>
                        <div class="illust">
                            <img :src="imgPath+'purchase_img03.png'" alt="">
                            <p class="tit">법인의 경우</p>
                        </div>
                        <img class="arrow_gray" src="/img/consulting/common/cons_info_area_gray_arrow.png" alt="">
                        <p class="desc">자기주식 취득 후 처분 시 손실이 발생할 경우 법인세 절세 효과가 있습니다. </p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area-round">
                <p class="tit_cons">2. 자사주 매입 기대 효과</p>
                <div class="plus_list">
                    <div class="plus_thumb">
                        <img :src="imgPath+'purchase_img04.png'" alt="">
                        <p class="tit">명목세율 20%</p>
                    </div>
                    <div class="plus_thumb">
                        <img :src="imgPath+'purchase_img05.png'" alt="">
                        <p class="tit">절세 자문 의뢰</p>
                    </div>
                    <div class="total">
                        <p class="sum">부담 실효세율<strong><em>0</em>%</strong>까지 이하</p>
                    </div>
                </div>
            </div>
            <div class="cons_info_area-round">
                <p class="tit_cons">3. 자사주 매입을 잘 활용하면 이것이 해결됩니다!</p>
                <ol class="orange_panel">
                    <li class="orange_box">
                        <p class="tit">대표이사<br> 가지급금 처리</p>
                        <p class="desc">쌓이면 세금폭탄이 되는 대표의 가지급금 처리에 활용</p>
                    </li>
                    <li class="orange_box">
                        <p class="tit">미처분이익잉여금<br> 정리</p>
                        <p class="desc">사내에 유보된 미처분이익잉여금을 활용, 자사주를 매입하여 정리 가능</p>
                    </li>
                    <li class="orange_box">
                        <p class="tit">가업승계에<br> 유리</p>
                        <p class="desc">주주 지분조정을 통해 가업승계 과정이 수월 </p>
                    </li>
                    <li class="orange_box">
                        <p class="tit">분산주주 정리를 통한<br> 대주주의결권 강화 </p>
                        <p class="desc">주식수를 줄여 대표의 의사결정 권한을 강화 하는 데 활용 </p>
                    </li>
                    <li class="orange_box">
                        <p class="tit">투자자금<br> 유치에 유리 </p>
                        <p class="desc">주식가치가 낮게 평가되는 시점에 자사주 매입을<br> 진행하면서 시장에 기업<br>성장성이 높다는 시그널을<br> 보내 투자자금 유치에<br> 효과적이어서 법인<br> 운영자금 확보에 유리 </p>
                    </li>
                    <li class="orange_box">
                        <p class="tit">주주의<br> 자본환원 </p>
                        <p class="desc">주주의 투자자금을<br> 환원하는 데 활용 가능 </p>
                    </li>                    
                    <li class="orange_box">
                        <p class="tit">임직원에게 스톡<br>그랜트/스톡옵션 가능 </p>
                        <p class="desc">신주에 대한 발행 없이<br> 직원에게 자사주로<br> 스톡그랜트나 스톡옵션 제공 </p>
                    </li>
                </ol>
            </div>
        </div>
    </section>
    <!-- //자사주매입 효과 -->

    <!-- 자사주 과세문제 -->
    <section id="collect" class="section">
        <div class="inner">
            <h3 class="tit_section">자사주 과세문제</h3>
            <p class="desc_section">
                <strong>자기주식 취득 시 취득목적에 따라 소득구분이 달라지는 것에 주의하여야 합니다.</strong><br>
                법인이 자기주식을  으로 취득한 경우에는 <strong>양도한 주주에게 양도소득세가 과세</strong>됩니다. <br>
                반면 <strong>소각목적</strong>으로 취득한 경우에는 해당 주주에게 의제배당으로 <strong>배당소득세가 과세</strong>됩니다.
            </p>
            <div class="cons_info_area-round border">
                <p class="tit_cons">1. 법인이 자기주식을 취득하여 보유하다가 처분하는 경우</p>
                <div class="box_round cons_info_area">
                    <div class="desc">
                        <p>주식을 양도한 주주의 양도차익에 대해서는 다른 소득과 합산되지 않고 분류 과세되며, 그 금액이 3억원 이하인 경우<br> 20%의 단일세율로 과세</p>
                    </div>
                    <div class="check_list">
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">최근 세법개정으로 대주주 양도차익 3억 초과분에 대해서는 25%로 적용세율이 높아졌으나, 근로소득이나 배당소득으로 종합과세 되는 경우<br> 소득금액 3억 초과시 적용세율 40~42%와 비교한다면 부담세액이 더 크지는 않음</p>
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">양도소득에 대해서는 국민연금과 건강보험료가 부과되지 않는다는 점에서 유리</p>
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">자기주식을 취득한 법인이 해당 주식을 처분하는 경우 회계상 자본거래이지만, 법인세법상 처분이익에 대해서는 법인세가 과세되고,<br> 처분손실에 대해서는 법인세가 감소하는 효과 발생</p>
                    </div>
                </div>
            </div>
            <div class="cons_info_area-round border">
                <p class="tit_cons">2. 법인이 취득한 자기주식을 소각하는 경우</p>
                <div class="box_round cons_info_area">
                    <div class="desc">
                        <p>주주의 양도차익 상당액에 대해 의제배당으로 과세되어 2천만원 이하인 경우 14% 분리과세로 종결되나,<br> 2천만원 초과되는 경우에는 다른 소득과 합산되어 누진세율로 과세 </p>
                    </div>
                    <div class="check_list">
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">급여 또는 상여나 배당으로 수령하는 경우에 비하여 절세 측면에서 크게 유리하지 않음</p>
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">자기주식을 취득하여 소각하는 법인은 세법상으로도 자본거래이므로 법인세 과세문제가 발생하지 않음</p>
                    </div>
                </div>
            </div>
            <div class="cons_info_area-round border">
                <p class="tit_cons">3. 자사주 매입 관련 절세 효과를 극대화 시키는 방법</p>
                <div class="box_round cons_info_area">
                    <div class="desc">
                        <p>주식을 배우자에게 증여재산공제 6억 범위 내에서 증여한 후 해당 주식을 회사가 자기주식으로 취득하여 소각</p>
                    </div>
                    <div class="check_list">
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">증여세 부담이 없고 의제배당 계산에서의 주식 취득가액이 증여재산가액이 되므로 의제배당이 발생하지 않아 소득세 부담 없음</p>
                        <p><img class="ico" src="" data-json-path="/image/svg/check01.svg">증여재산에서 공제 받을 수 있는 금액은 10년 이내 배우자는 6억, 직계존속은 5천만원(직계존속과 그 배우자는 동일인으로 봄),<br> 직계비속은 5천만원(미성년자의 경우 2천만원)</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //자사주 과세문제 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess_b data-list-idx="3" data-title="자사주 매입 실행 절차" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="section"></section>
    <!-- //무료 컨설팅 상담신청 -->

    <!-- 컨설팅 분야 바로가기 -->
    <consultingField_b />
    <!-- //컨설팅 분야 바로가기 -->
</main><!-- /main -->
<!-- //container -->
</template>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){




        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    },
    data() {
        return {
            imgPath: '/img/consulting/purchase_b/'
        }
    }
}

</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/purchase_b.scss';
</style>